import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  measurementsSelector,
  loadingSelector,
  userDataSelector
} from '../../../store/userInfo/selectors';

import UserInfoTable from './UserInfoTable';

const deviceIdSelector = createSelector(
  userDataSelector,
  (data) => (data?.current_device ? data.current_device.id : null)
);

const mapStateToProps = createStructuredSelector({
  measurements: measurementsSelector,
  loading: loadingSelector,
  deviceId: deviceIdSelector
});

export default connect(mapStateToProps)(UserInfoTable);
