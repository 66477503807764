import { createSelector } from 'reselect';
import { DateTime } from 'luxon';

const slice = ({ userInfo }) => userInfo;

export const loadingSelector = createSelector(slice, ({ loading }) => loading);
export const filterSelector = createSelector(slice, ({ filter }) => filter ?? DateTime.now().toISODate());
export const activeMeasurementSelector = createSelector(slice, ({ isActiveMeasurement }) => isActiveMeasurement);
export const bluetoothSelector = createSelector(slice, ({ isBluetooth }) => isBluetooth);
export const internetSelector = createSelector(slice, ({ isInternet }) => isInternet);
export const userDataSelector = createSelector(slice, ({ data }) => data);
export const measurementsSelector = createSelector(slice, ({ measurements }) => measurements);
export const userIdSelector = createSelector(
  userDataSelector,
  (user) => (user ? user.id : null)
);
