import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from '../../ui/Text';
import Icon from '../../ui/Icon';
import Battery from '../../Battery';

import style from './StatusBar.module.scss';

const StatusBar = ({
  bluetooth,
  internet,
  chargeLevel
}) => (
  <div className={style.component}>
    <div
      className={cn(style.internet, {
        [style.gray]: !internet
      })}
    >
      {internet && (
        <Icon
          name="TapNPlay"
          width="14"
          height="18"
        />
      )}
      {!internet && (
        <Icon
          name="TapNPlayCrossed"
          width="20"
          height="20"
        />
      )}
    </div>

    <div
      className={cn(style.bluetooth, {
        [style.gray]: !bluetooth
      })}
    >
      {bluetooth && (
        <Icon
          name="Bluetooth"
          width="17"
          height="19"
        />
      )}
      {!bluetooth && (
        <Icon
          name="BluetoothCrossed"
          width="20"
          height="20"
        />
      )}
    </div>

    {!!chargeLevel && bluetooth && (
      <div className={style.charge}>
        <Battery level={chargeLevel} />
        <Text
          semibold
          size="XS"
          className={style.batteryText}
        >
          {chargeLevel}%
        </Text>
      </div>
    )}
  </div>
);

StatusBar.propTypes = {
  bluetooth: PropTypes.bool,
  internet: PropTypes.bool,
  chargeLevel: PropTypes.number
};

StatusBar.defaultProps = {
  bluetooth: true,
  internet: true,
  chargeLevel: 35
};

export default memo(StatusBar);
