import React, {
  memo,
  forwardRef,
  useCallback,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-IN';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerOverride.scss';

import Icon from '../../ui/Icon';
import Text from '../../ui/Text';

import { noopPromise, formatDateToShortString } from '../../../utils';

import style from './DateFilter.module.scss';

const locales = {
  en,
  ru
};

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button
    ref={ref}
    type="button"
    className={style.mainButton}
    onClick={onClick}
  >
    <Icon
      name="Calendar"
      width="18"
      height="20"
    />

    <Text
      semibold
      className={style.value}
    >
      {formatDateToShortString(value)}
    </Text>
  </button>
));

ExampleCustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

ExampleCustomInput.defaultProps = {
  onClick: noopPromise,
  value: ''
};

const DateFilter = ({
  onFilterChange,
  filter,
  lang,
  loading
}) => {
  const [startDate, setStartDate] = useState(DateTime.fromISO(filter).toJSDate());

  useEffect(() => {
    if (!filter) {
      return;
    }
    setStartDate(DateTime.fromISO(filter).toJSDate());
  }, [filter]);

  const onChangeProxy = useCallback((date) => {
    const isoDate = DateTime.fromJSDate(date).toISODate();

    setStartDate(date);
    onFilterChange(isoDate);
  }, [onFilterChange]);

  const onNextButtonClick = useCallback(() => {
    const newDate = DateTime.fromJSDate(startDate).plus({ days: 1 });

    setStartDate(newDate.toJSDate());
    onFilterChange(newDate.toISODate());
  }, [onFilterChange, startDate]);

  const onPrevButtonClick = useCallback(() => {
    const newDate = DateTime.fromJSDate(startDate).minus({ days: 1 });

    setStartDate(newDate.toJSDate());
    onFilterChange(newDate.toISODate());
  }, [onFilterChange, startDate]);

  return (
    <div className={style.component}>
      <button
        type="button"
        className={style.prevMonth}
        disabled={loading}
        onClick={onPrevButtonClick}
      >
        <Icon
          name="Arrow"
          width="9"
          height="15"
        />
      </button>

      <DatePicker
        selected={startDate}
        dateFormat="yyyy-MM-dd"
        customInput={<ExampleCustomInput />}
        showPopperArrow={false}
        popperPlacement="bottom"
        locale={locales[lang]}
        onChange={onChangeProxy}
      />

      <button
        type="button"
        className={style.nextMonth}
        disabled={loading}
        onClick={onNextButtonClick}
      >
        <Icon
          name="Arrow"
          width="9"
          height="15"
        />
      </button>
    </div>
  );
};

DateFilter.propTypes = {
  onFilterChange: PropTypes.func,
  filter: PropTypes.string,
  lang: PropTypes.string,
  loading: PropTypes.bool
};

DateFilter.defaultProps = {
  onFilterChange: noopPromise,
  filter: null,
  lang: 'ru',
  loading: false
};

export default memo(DateFilter);
