import { connect } from 'react-redux';

import { logout, clearTokens } from '../../store/auth/actions';
import { setFilter, setSearchValue, setSelectedUsers } from '../../store/users/actions';

import LogoutButton from './LogoutButton';

const mapDispatchToProps = {
  onClick: () => (dispatch) => {
    clearTokens();
    dispatch(setFilter([]));
    dispatch(setSearchValue(''));
    dispatch(setSelectedUsers([]));
    dispatch(logout());
  }
};

export default connect(null, mapDispatchToProps)(LogoutButton);
