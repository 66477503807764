import axios from 'axios';
import store from '../store';

import { logout } from '../store/auth/actions';

axios.defaults.baseURL = process.env.REACT_APP_API_ORIGIN ? `https://${process.env.REACT_APP_API_ORIGIN}/api/v1` : '/api/v1'; // eslint-disable-line
axios.defaults.withCredentials = true;
axios.interceptors.request.use(async(config) => {
  const {
    auth: { token },
    app: { lang }
  } = store.getState();

  const storageToken = localStorage.getItem('auth_token');

  config.headers['Accept-Language'] = lang; // eslint-disable-line

  if (token && token?.length !== 0) {
    config.headers.Authorization = 'Token ' + token; // eslint-disable-line
  } else if (storageToken && storageToken?.length !== 0) {
    config.headers.Authorization = 'Token ' + storageToken; // eslint-disable-line
  }

  return config;
});
axios.interceptors.response.use(
  (response) => response,
  (err) => {
    const errStatus = err?.response?.status;
    if (errStatus === 401) {
      localStorage.removeItem('auth_token');
      store.dispatch(logout());
    }

    return Promise.reject(err);
  }
);
