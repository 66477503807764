import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import style from './Spinner.module.scss';

const Spinner = ({
  size
}) => {
  const rootStyle = useMemo(() => ({
    width: size,
    height: size
  }), [size]);

  return (
    <div
      className={style.component}
      style={rootStyle}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.number
};

Spinner.defaultProps = {
  size: 24
};

export default memo(Spinner);
