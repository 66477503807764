import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { createStructuredSelector, createSelector } from 'reselect';
import i18n from '../../../services/i18next';

import {
  measurementsSelector,
  loadingSelector
} from '../../../store/userInfo/selectors';

import { langSelector } from '../../../store/app/selectors';

import Chart from './Chart';

const chartTimeType = createSelector(
  measurementsSelector,
  (measurements) => {
    const measurementsLength = measurements.length;
    if (measurementsLength === 0) {
      return DateTime.TIME_24_SIMPLE;
    }

    const firstMeasurement = DateTime.fromISO(measurements[0]?.timestamp);
    const lastMeasurement = DateTime.fromISO(measurements[measurementsLength - 1]?.timestamp);
    const difference = lastMeasurement.diff(firstMeasurement, ['minutes']).minutes;

    return difference > 24 ? DateTime.TIME_24_SIMPLE : DateTime.TIME_24_WITH_SECONDS;
  }
);

const chartMeasurements = createSelector(
  measurementsSelector,
  langSelector,
  chartTimeType,
  (measurements, lang, labelTimeType) => measurements.map((measurement) => ({
    time: DateTime.fromISO(measurement.timestamp).setLocale(lang || i18n.language || 'ru').toLocaleString(labelTimeType), // eslint-disable-line
    temp: Number(measurement.value.toFixed(1))
  }))
);

const mapStateToProps = createStructuredSelector({
  measurements: chartMeasurements,
  loading: loadingSelector
});

export default connect(mapStateToProps)(Chart);
