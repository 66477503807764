import React, { memo } from 'react';
import PropTypes from 'prop-types';

import style from './Label.module.scss';

const Label = ({
  htmlFor,
  children,
  ...props
}) => (
  <div
    className={style.component}
  >
    <label
      htmlFor={htmlFor}
      className={style.label}
      {...props}
    >
      {children}
    </label>
  </div>
);

Label.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.node
};

Label.defaultProps = {
  htmlFor: null,
  children: null
};

export default memo(Label);
