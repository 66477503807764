import { createSelector } from 'reselect';

const slice = ({ auth }) => auth;

export const tokenSelector = createSelector(slice, ({ token }) => token);
export const refreshTokenSelector = createSelector(slice, ({ refreshToken }) => refreshToken);
export const tokenDataSelector = createSelector(slice, ({ tokenData }) => tokenData);
export const authorizedSelector = createSelector(slice, ({ authorized }) => authorized);
export const refreshingSelector = createSelector(slice, ({ refreshing }) => refreshing);
export const deferredActionsSelector = createSelector(slice, ({ deferredActions }) => deferredActions);

export const userIdSelector = createSelector(tokenDataSelector, (data) => (data ? data.user_id : null));
