import axios from 'axios';

import { updateUserMeasurements, updateBatteryLevel } from './userInfo/actions';

const initialReconnectDelay = 1000;
let currentReconnectDelay = initialReconnectDelay;
const maxReconnectDelay = 16000;
let socket = null;

const getTicket = () => axios.get('auth/ticket/').then(({ data }) => data);

export const openSocket = (deviceId) => async(dispatch) => {
  const ticket = await getTicket();

  socket = new WebSocket(`wss://${process.env.REACT_APP_API_ORIGIN}/ws/device/${deviceId}/?ticket=${ticket}`);

  socket.onopen = () => {
    currentReconnectDelay = initialReconnectDelay;
  };

  socket.onmessage = (e) => {
    const { message } = JSON.parse(e.data);

    dispatch(updateUserMeasurements(message?.values || []));
    dispatch(updateBatteryLevel(message?.battery_level || null));
  };

  socket.onclose = (e) => {
    socket = null;
    if (e.wasClean) {
      return;
    }

    setTimeout(() => {
      if (currentReconnectDelay < maxReconnectDelay) {
        currentReconnectDelay *= 2;
      }
      dispatch(openSocket(deviceId));
    }, currentReconnectDelay + Math.floor(Math.random() * 3000));
  };

  socket.onerror = () => {
    socket.close();
  };
};

export const closeSocket = () => {
  socket?.close();
  socket = null;
};
