// import jwtDecode from 'jwt-decode';

import { createReducer } from '../utils';
import {
  SET_TOKEN,
  DELETE_TOKEN
} from './constants';

// const getTokenData = (token) => {
//   let data = null;

//   try {
//     data = jwtDecode(token);
//   } catch (err) {
//     console.error(err); // eslint-disable-line no-console
//   }

//   return data;
// };

const initialState = {
  token: null,
  authorized: false
};

const auth = createReducer(initialState, {
  [SET_TOKEN]: (state, { payload }) => ({
    ...state,
    token: payload,
    authorized: true
  }),

  [DELETE_TOKEN]: (state) => ({
    ...state,
    token: null,
    authorized: false
  })
});

export default auth;
