import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

// import config from '../config';

// import { onUnauthorized } from './auth/actions';
// import { addErrorNotification } from './notifications/actions';

// import createApiMiddleware from './middlewares/createApiMiddleware';
// import createAuthMiddleware from './middlewares/createAuthMiddleware';
// import createErrorsMiddleware from './middlewares/createErrorsMiddleware';

import app from './app/reducer';
import auth from './auth/reducer';
import users from './users/reducer';
import userInfo from './userInfo/reducer';
// import account from './account/reducer';
// import cards from './cards/reducer';
// import card from './card/reducer';
// import notifications from './notifications/reducer';

const middleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true
});

// const configureStore = (initialState) => {
//   const middlewares = [
//     thunkMiddleware
//     // createApiMiddleware({
//     //   protocol: config.API_PROTOCOL,
//     //   host: config.API_HOST,
//     //   apiPath: config.API_PATH
//     // }),
//     // createAuthMiddleware(onUnauthorized),
//     // createErrorsMiddleware(onApiError)
//   ];

//   const composeEnhancers = composeWithDevTools({ name: 'EVITEMP_WEBAPP' });
//   const enhancers = composeEnhancers(applyMiddleware(...middlewares));

//   return createStore(
//     createRootReducer(),
//     initialState,
//     enhancers
//   );
// };

export default configureStore({
  reducer: {
    app,
    auth,
    users,
    userInfo
  },
  middleware,
  devTools: process.env.NODE_ENV !== 'production'
});
