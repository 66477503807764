import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import { exportUser } from '../../../store/userInfo/actions';
import { exportUsers } from '../../../store/users/actions';

import { userIdSelector, filterSelector } from '../../../store/userInfo/selectors';
import { selectedUsersSelector } from '../../../store/users/selectors';

import ExportButton from './ExportButton';

const mapDispatchToProps = {
  exportUser: (extension = 'csv') => (dispatch, getState) => {
    const id = userIdSelector(getState());
    const filter = filterSelector(getState());
    return dispatch(exportUser({
      id,
      extension,
      startDate: filter,
      endDate: DateTime.fromISO(filter).plus({ days: 1 }).toISODate()
    }));
  },
  exportUsers: (extension = 'csv') => (dispatch, getState) => {
    const selectedUsers = selectedUsersSelector(getState());
    return dispatch(exportUsers(selectedUsers, extension));
  }
};

export default connect(null, mapDispatchToProps)(ExportButton);
