import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import { getList } from '../../store/users/actions';
import { loadingSelector, usersDataSelector } from '../../store/users/selectors';

import Users from './Users';

const noUsers = createSelector(
  usersDataSelector,
  (users) => (users ? users.length === 0 : false)
);

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector,
  noUsers
});

const mapDispatchToProps = {
  onMount: getList
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
