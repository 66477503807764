import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Heading.module.scss';

const Heading = ({
  level,
  className,
  children,
  ...props
}) => {
  const Tag = `h${level}`;

  return (
    <Tag
      className={cn(style.component, className)}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Tag>
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf([
    1, '1',
    2, '2',
    3, '3'
  ]),
  className: PropTypes.string,
  children: PropTypes.node
};

Heading.defaultProps = {
  level: 1,
  className: null,
  children: null
};

export default memo(Heading);
