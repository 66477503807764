import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  langSelector
} from '../../store/app/selectors';

import { setLang } from '../../store/app/actions';

import LanguageSwitch from './LanguageSwitch';

const mapStateToProps = createStructuredSelector({
  lang: langSelector
});

const mapDispatchToProps = {
  setLang
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);
