import React, {
  memo,
  useMemo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Text from '../../../ui/Text';
import Icon from '../../../ui/Icon';

import { noopPromise, noop, isNotEmptyArray } from '../../../../utils';

import style from './Pagination.module.scss';

const Pagination = ({
  prev,
  next,
  count,
  getNewData,
  loading,
  selectedUsersCount,
  filter,
  onResetFilter,
  isSelectedUsers,
  onResetUsersSelect,
  page,
  setPage
}) => {
  const { t } = useTranslation();

  const pageDistance = useMemo(() => {
    const firstNumber = page === 1 ? 1 : (page - 1) * 10 + 1;
    const lastNumber = page * 10 >= count ? count : page * 10;

    return `${firstNumber}-${lastNumber}`;
  }, [page, count]);

  const onPrevClick = useCallback(() => {
    getNewData(prev).then(() => setPage(page - 1));
  }, [getNewData, prev, setPage, page]);

  const onNextClick = useCallback(() => {
    getNewData(next).then(() => setPage(page + 1));
  }, [getNewData, next, setPage, page]);

  return (
    <div className={style.component}>
      <div className={style.left}>
        {isNotEmptyArray(filter) && (
          <Text
            size="XS"
            thin
            className={style.leftInner}
          >
            {t('found')} {count} {t('users')}
          </Text>
        )}

        {isNotEmptyArray(filter) && (
          <button
            type="button"
            className={cn(style.resetFilter, style.leftInner)}
            onClick={onResetFilter}
          >
            <Text
              size="XS"
              className={style.resetFilterText}
            >
              {t('resetFilter')}
            </Text>
          </button>
        )}

        {!!selectedUsersCount && (
          <Text
            size="XS"
            thin
            className={style.leftInner}
          >
            {t('selected')} {selectedUsersCount} {t('users')}
          </Text>
        )}

        {isSelectedUsers && (
          <button
            type="button"
            className={cn(style.resetFilter, style.leftInner)}
            onClick={onResetUsersSelect}
          >
            <Text
              size="XS"
              className={style.resetFilterText}
            >
              {t('clearSelection')}
            </Text>
          </button>
        )}
      </div>

      {!!count && (
        <div className={style.right}>
          <div className={style.info}>
            <Text
              thin
              size="S"
            >
              {pageDistance} / {count}
            </Text>
          </div>
          <div className={style.buttons}>
            <button
              type="button"
              className={cn(style.prev, {
                [style.active]: !!prev
              })}
              disabled={loading || !prev}
              onClick={onPrevClick}
            >
              <Icon
                name="Arrow"
                width="7"
                height="12"
              />
            </button>

            <button
              type="button"
              className={cn(style.next, {
                [style.active]: !!next
              })}
              disabled={loading || !next}
              onClick={onNextClick}
            >
              <Icon
                name="Arrow"
                width="7"
                height="12"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  prev: PropTypes.string,
  next: PropTypes.string,
  count: PropTypes.number,
  getNewData: PropTypes.func,
  loading: PropTypes.bool,
  selectedUsersCount: PropTypes.number,
  filter: PropTypes.array,
  onResetFilter: PropTypes.func,
  isSelectedUsers: PropTypes.bool,
  onResetUsersSelect: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func
};

Pagination.defaultProps = {
  prev: null,
  next: null,
  count: null,
  getNewData: noopPromise,
  loading: false,
  selectedUsersCount: 0,
  filter: [],
  onResetFilter: noopPromise,
  isSelectedUsers: false,
  onResetUsersSelect: noopPromise,
  page: 1,
  setPage: noop
};

export default memo(Pagination);
