import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import { userDataSelector, userIdSelector } from '../../../store/userInfo/selectors';

import { updateUser } from '../../../store/userInfo/actions';

import FormAccount from './FormAccount';

const initialDataSelector = createSelector(
  userDataSelector,
  (data) => ({
    full_name: data?.full_name || ''
  })
);

const mapStateToProps = createStructuredSelector({
  initialValue: initialDataSelector
});

const mapDispatchToProps = {
  onSubmit: (data) => (dispatch, getState) => {
    const userId = userIdSelector(getState());
    return dispatch(updateUser(userId, data));
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAccount);
