import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Icon from '../../ui/Icon';
import Dropdown from '../../ui/Dropdown';
import Text from '../../ui/Text';

import { noopPromise } from '../../../utils';

import style from './ExportButton.module.scss';

const options = ['csv', 'pdf', 'xlsx'];

const ExportButton = ({
  exportUser,
  exportUsers,
  disabled,
  type
}) => {
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  return (
    <div
      className={cn(style.component, {
        [style.disabled]: disabled
      })}
    >
      <Dropdown
        ref={dropdownRef}
        trigger={(
          <div
            className={style.trigger}
            activeclass={style.triggerActive}
          >
            <Icon
              name="Download"
              width="16"
              height="20"
            />

            <Text
              thin
              size="S"
              className={style.langText}
            >
              {t('save')}
            </Text>

            <Icon
              name="Arrow"
              width="8"
              height="12"
              className={style.arrowIcon}
            />
          </div>
        )}
      >
        <div className={style.content}>
          {options.map((opt, idx) => {
            const onExtentionClick = (value) => {
              if (type === 'single') {
                exportUser(value);
              } else {
                exportUsers(value);
              }

              dropdownRef.current?.closeDropdown();
            };
            return (
              <div // eslint-disable-line
                key={idx} // eslint-disable-line
                className={style.option}
                onClick={() => onExtentionClick(opt)} // eslint-disable-line
              >
                <Text
                  thin
                  size="XS"
                  className={style.optionText}
                >
                  {opt}
                </Text>
              </div>
            );
          })}
        </div>
      </Dropdown>
    </div>
  );
};

ExportButton.propTypes = {
  exportUser: PropTypes.func,
  exportUsers: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([
    'single',
    'many'
  ])
};

ExportButton.defaultProps = {
  exportUser: noopPromise,
  exportUsers: noopPromise,
  disabled: false,
  type: 'single'
};

export default memo(ExportButton);
