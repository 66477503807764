import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const RedirectConditionRoute = ({
  children: Children,
  render: Render,
  component: Component,
  redirect,
  redirectTo,
  ...props
}) => (
  <Route
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    {(innerProps) => {
      if (redirect) {
        return (
          <Redirect to={redirectTo} />
        );
      }

      if (Children) {
        if (typeof Children === 'function') {
          return (
            <Children
              {...innerProps} // eslint-disable-line react/jsx-props-no-spreading
            />
          );
        }

        return Children;
      }

      if (Component) {
        return (
          <Component
            {...innerProps} // eslint-disable-line react/jsx-props-no-spreading
          />
        );
      }

      if (Render) {
        return (
          <Render
            {...innerProps} // eslint-disable-line react/jsx-props-no-spreading
          />
        );
      }

      return null;
    }}
  </Route>
);

RedirectConditionRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func
  ]),
  render: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func
  ]),
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func
  ]),
  redirect: PropTypes.bool,
  redirectTo: PropTypes.string
};

RedirectConditionRoute.defaultProps = {
  children: null,
  render: null,
  component: null,
  redirect: false,
  redirectTo: '/'
};

export default memo(RedirectConditionRoute);
