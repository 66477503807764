import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Spinner from '../Spinner';

import style from './ButtonWithSpinner.module.scss';

const ButtonWithSpinner = ({
  theme,
  processing,
  disabled,
  children,
  ...props
}) => (
  <Button
    disabled={disabled || processing}
    theme={theme}
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    {!processing && children}

    {processing && (
      <div className={style.spinner}>
        <Spinner
          size={24}
        />
      </div>
    )}
  </Button>
);

ButtonWithSpinner.propTypes = {
  theme: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger'
  ]),
  processing: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

ButtonWithSpinner.defaultProps = {
  theme: 'primary',
  processing: false,
  disabled: false,
  children: null
};

export default memo(ButtonWithSpinner);
