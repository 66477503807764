import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Heading from '../../components/ui/Heading';
import FormLogin from '../../components/forms/FormLogin';

import style from './SignIn.module.scss';

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <div className={style.component}>
      <div className={style.container}>
        <div className={style.logo} />

        <Heading className={style.heading}>
          {t('signInHeader')}
        </Heading>

        <FormLogin />
      </div>
    </div>
  );
};

SignIn.propTypes = {};

export default memo(SignIn);
