import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { loginJWT } from '../../../store/auth/actions';
import { langSelector } from '../../../store/app/selectors';

import FormLogin from './FormLogin';

const mapStateToProps = createStructuredSelector({
  lang: langSelector
});

const mapDispatchToProps = {
  onSubmit: loginJWT
};

export default connect(mapStateToProps, mapDispatchToProps)(FormLogin);
