import { createSelector } from 'reselect';

const slice = ({ users }) => users;

export const loadingSelector = createSelector(slice, ({ loading }) => loading);
export const filterSelector = createSelector(slice, ({ filter }) => filter);
export const searchSelector = createSelector(slice, ({ search }) => search);
export const selectedUsersSelector = createSelector(slice, ({ selectedUsers }) => selectedUsers);
export const usersDataSelector = createSelector(slice, ({ data }) => data);
export const usersListSelector = createSelector(usersDataSelector, (data) => (data ? data.results : []));
export const usersCountSelector = createSelector(usersDataSelector, (data) => (data ? data.count : null));
export const usersNextLinkSelector = createSelector(usersDataSelector, (data) => (data ? data.next : null));
export const usersPrevLinkSelector = createSelector(usersDataSelector, (data) => (data ? data.previous : null));
export const pageSelector = createSelector(slice, ({ page }) => page);
