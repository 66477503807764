import React, { memo, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { createArray } from '../../../utils';

import style from './LineSpinner.module.scss';

const LineSpinner = ({
  size = 32, thickness = 2, theme = 'primary', duration = 1000
}) => {
  const rootStyle = useMemo(
    () => ({
      width: size,
      height: size
    }),
    [size]
  );

  const radians = useMemo(
    () => createArray(4, (_, index) => ({
      key: index,
      style: {
        borderWidth: Math.min(thickness, size / 2),
        animationDuration: `${duration}ms`,
        animationDelay: `${0.375 * duration - index * 0.125 * duration}ms`
      }
    })),
    [size, thickness, duration]
  );

  return (
    <div
      className={cn(style.component, style[`theme__${theme}`])}
      style={rootStyle}
    >
      {radians.map((radian) => (
        <div
          key={radian.key}
          className={style.radian}
          style={radian.style}
        />
      ))}
    </div>
  );
};

LineSpinner.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  theme: PropTypes.string,
  duration: PropTypes.number
};

LineSpinner.defaultProps = {
  size: 32,
  thickness: 2,
  theme: 'primary',
  duration: 1000
};

export default memo(LineSpinner);
