import React, {
  memo,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
  useEffect
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import style from './Dropdown.module.scss';

const Dropdown = forwardRef(({
  trigger,
  children,
  contentMargin
}, ref) => {
  const triggerRef = useRef(null);
  const [open, setOpen] = useState(false);

  // const handleOpenDroprown = useCallback(() => {
  //   setOpen(true);
  // }, []);

  const handleCloseDroprown = useCallback(() => {
    setOpen(false);
  }, []);

  const handleToggleDroprown = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  useImperativeHandle(ref, () => ({
    closeDropdown() {
      setOpen(false);
    }
  }));

  const computedTopOfContentStyle = useMemo(() => ({
    top: `${(triggerRef.current?.offsetHeight || 16) + contentMargin}px`
  }), [triggerRef.current, contentMargin]); // eslint-disable-line

  useEffect(() => {
    const onClick = (e) => {
      e.stopPropagation();
      const { target } = e;
      if (triggerRef.current && !triggerRef.current.contains(target)) {
        handleCloseDroprown();
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [handleCloseDroprown, triggerRef]);

  return (
    <div
      ref={ref}
      className={style.component}
      tabIndex={0} // eslint-disable-line
      // onFocus={handleOpenDroprown}
      // onBlur={handleCloseDroprown}
    >
      <button
        ref={triggerRef}
        type="button"
        className={cn(style.trigger, {
          [style.open]: open
        })}
        onClick={handleToggleDroprown}
      >
        {React.cloneElement(trigger, {
          className: cn(trigger.props.className, {
            [style.active]: open,
            [trigger.props.activeclass]: open
          })
        })}
      </button>

      <div
        style={computedTopOfContentStyle}
        className={cn(style.content, {
          [style.open]: open
        })}
      >
        {children}
      </div>
    </div>
  );
});

Dropdown.propTypes = {
  trigger: PropTypes.node,
  children: PropTypes.node,
  contentMargin: PropTypes.number
};

Dropdown.defaultProps = {
  trigger: null,
  children: null,
  contentMargin: 8
};

export default memo(Dropdown);
