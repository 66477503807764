import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import Text from '../../ui/Text';

import { formatDateToString, getColorFromTemperature } from '../../../utils';

import style from './UserInfoTable.module.scss';

const rows = [1, 2, 3, 4, 5];
const skeletonStyles = {
  borderRadius: '2px',
  lineHeight: '32px'
};

const SkeletonRows = () => rows.map((_, idx) => (
  <tr
    key={idx} // eslint-disable-line
  >
    <td>
      <Skeleton
        baseColor="#F7F9FB"
        style={skeletonStyles}
      />
    </td>
    <td>
      <Skeleton
        baseColor="#F7F9FB"
        style={skeletonStyles}
      />
    </td>
    <td>
      <Skeleton
        baseColor="#F7F9FB"
        style={skeletonStyles}
      />
    </td>
  </tr>
));

const UsersTable = ({
  measurements,
  loading,
  deviceId
}) => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <div className={style.component}>
        <table className={style.table}>
          <thead className={style.header}>
            <tr>
              <td>
                <Text semibold>
                  {t('date')}
                </Text>
              </td>
              <td>
                <Text semibold>
                  {t('measurement')}
                </Text>
              </td>
              <td>
                <Text semibold>
                  {t('device')}
                </Text>
              </td>
            </tr>
          </thead>
          <tbody className={style.body}>
            {!loading && measurements.map((measurement, idx) => {
              const temperatureClass = getColorFromTemperature(measurement?.value);

              return (
                <tr
                  key={idx} // eslint-disable-line
                  className={style.row}
                >
                  <td>
                    <Text
                      thin
                      size="S"
                    >
                      {formatDateToString(measurement?.timestamp)}
                    </Text>
                  </td>
                  <td>
                    {measurement?.value && (
                      <Text
                        semibold
                        size="S"
                        className={cn(style.temperature, style[temperatureClass])}
                      >
                        {measurement?.value.toFixed(1)} °C
                      </Text>
                    )}
                    {!measurement.value && (
                      <Text
                        thin
                        size="S"
                        className={style.noData}
                      >
                        {t('noData')}
                      </Text>
                    )}
                  </td>
                  <td>
                    {deviceId && (
                      <Text
                        thin
                        size="S"
                      >
                        UID: {deviceId}
                      </Text>
                    )}
                    {!deviceId && (
                      <Text
                        thin
                        size="S"
                        className={style.noData}
                      >
                        -
                      </Text>
                    )}
                  </td>
                </tr>
              );
            })}

            {loading && (
              <SkeletonRows />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  measurements: PropTypes.array,
  loading: PropTypes.bool,
  deviceId: PropTypes.string
};

UsersTable.defaultProps = {
  measurements: [],
  loading: false,
  deviceId: null
};

export default memo(UsersTable);
