// import jwtDecode from 'jwt-decode';

import { isNotEmptyArray } from '../../utils';
import { createReducer } from '../utils';
import {
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  SET_FILTER,
  SET_SEARCH_VALUE,
  SET_SELECTED_USERS,
  SET_PAGE,
  DELETE_USER_SUCCESS
} from './constants';

const initialState = {
  data: null,
  loading: false,
  filter: localStorage.getItem('filter') !== null ? JSON.parse(localStorage.getItem('filter')) : [],
  search: localStorage.getItem('search') || '',
  selectedUsers: localStorage.getItem('selected') !== null ? JSON.parse(localStorage.getItem('selected')) : [],
  page: 1
};

const auth = createReducer(initialState, {
  [GET_USERS_LIST]: (state) => ({
    ...state,
    loading: true
  }),

  [GET_USERS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    loading: false
  }),

  [SET_FILTER]: (state, { payload }) => {
    const { filter: prevFilter } = state;
    if (Array.isArray(payload) && !isNotEmptyArray(payload)) {
      localStorage.setItem('filter', JSON.stringify([]));
      return {
        ...state,
        filter: []
      };
    }

    const newFilterValue = prevFilter.includes(payload)
      ? prevFilter.filter((v) => v !== payload)
      : [...prevFilter, payload];

    localStorage.setItem('filter', JSON.stringify(newFilterValue));

    return {
      ...state,
      filter: newFilterValue
    };
  },

  [SET_SEARCH_VALUE]: (state, { payload }) => {
    localStorage.setItem('search', payload);
    return {
      ...state,
      search: payload
    };
  },

  [SET_SELECTED_USERS]: (state, { payload }) => {
    localStorage.setItem('selected', JSON.stringify(payload));
    return {
      ...state,
      selectedUsers: payload
    };
  },

  [DELETE_USER_SUCCESS]: (state, { payload }) => {
    const newSelectedUsers = state.selectedUsers.filter((user) => user !== payload);
    localStorage.setItem('selected', JSON.stringify(newSelectedUsers));
    return {
      ...state,
      selectedUsers: newSelectedUsers
    };
  },

  [SET_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload
  })
});

export default auth;
