import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  getUser,
  getUserMeasurements,
  updateUserStatuses,
  setFilter
} from '../../store/userInfo/actions';
import {
  loadingSelector, filterSelector, measurementsSelector
} from '../../store/userInfo/selectors';
import { openSocket } from '../../store/socketConnection';

import UserInfo from './UserInfo';

const noMeasurements = createSelector(
  measurementsSelector,
  (measurements) => (measurements ? measurements.length === 0 : false)
);

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector,
  filter: filterSelector,
  noMeasurements
});

const mapDispatchToProps = {
  onMount: getUser,
  getUserMeasurements: (userId) => (dispatch, getState) => {
    const filter = filterSelector(getState());
    return dispatch(getUserMeasurements(userId, filter));
  },
  openSocket,
  updateUserStatuses,
  setFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
