import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Text.module.scss';

const Text = ({
  size,
  bold,
  semibold,
  thin,
  children,
  className,
  ...props
}) => (
  <span
    className={cn(
      style.component,
      style[`size-${size}`],
      {
        [style.bold]: bold,
        [style.semibold]: semibold,
        [style.thin]: thin
      },
      className
    )}
    {...props}
  >
    {children}
  </span>
);

Text.propTypes = {
  size: PropTypes.oneOf([
    'L',
    'M',
    'S',
    'XS',
    'XXS'
  ]),
  bold: PropTypes.bool,
  semibold: PropTypes.bool,
  thin: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

Text.defaultProps = {
  size: 'M',
  bold: false,
  semibold: false,
  thin: false,
  className: null,
  children: null
};

export default memo(Text);
