import React, { memo } from 'react';
import PropTypes from 'prop-types';

import style from './ErrorMessage.module.scss';

const ErrorMessage = ({ children, ...props }) => (
  <div
    className={style.component}
    {...props}
  >
    {children}
  </div>
);

ErrorMessage.propTypes = {
  children: PropTypes.node
};

ErrorMessage.defaultProps = {
  children: null
};

export default memo(ErrorMessage);
