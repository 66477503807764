import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as Arrow } from './icons/Arrow.svg';
import { ReactComponent as Battery } from './icons/Battery.svg';
import { ReactComponent as Bluetooth } from './icons/Bluetooth.svg';
import { ReactComponent as BluetoothCrossed } from './icons/BluetoothCrossed.svg';
import { ReactComponent as Calendar } from './icons/Calendar.svg';
import { ReactComponent as CrossedEye } from './icons/CrossedEye.svg';
import { ReactComponent as Download } from './icons/Download.svg';
import { ReactComponent as Eye } from './icons/Eye.svg';
import { ReactComponent as Logout } from './icons/Logout.svg';
import { ReactComponent as Minus } from './icons/Minus.svg';
import { ReactComponent as Pencil } from './icons/Pencil.svg';
import { ReactComponent as Plus } from './icons/Plus.svg';
import { ReactComponent as Setting } from './icons/Setting.svg';
import { ReactComponent as TapNPlay } from './icons/TapNPlay.svg';
import { ReactComponent as TapNPlayCrossed } from './icons/TapNPlayCrossed.svg';
import { ReactComponent as Trash } from './icons/Trash.svg';
import { ReactComponent as Check } from './icons/Check.svg';
import { ReactComponent as Filter } from './icons/Filter.svg';
import { ReactComponent as Search } from './icons/Search.svg';
import { ReactComponent as Clear } from './icons/Clear.svg';
import { ReactComponent as Done } from './icons/Done.svg';
import { ReactComponent as Online } from './icons/Online.svg';
import { ReactComponent as Offline } from './icons/Offline.svg';

import style from './Icon.module.scss';

const ICON_NAME_MAP = {
  Arrow,
  Battery,
  Bluetooth,
  BluetoothCrossed,
  Calendar,
  CrossedEye,
  Download,
  Eye,
  Logout,
  Minus,
  Pencil,
  Plus,
  Setting,
  TapNPlay,
  TapNPlayCrossed,
  Trash,
  Check,
  Filter,
  Search,
  Clear,
  Done,
  Online,
  Offline
};

const Icon = ({
  name,
  size,
  width,
  height,
  className,
  ...props
}) => {
  const Component = useMemo(() => ICON_NAME_MAP[name], [name]);
  const computedWidth = useMemo(() => size || width, [size, width]);
  const computedHeight = useMemo(() => size || height, [size, height]);

  if (!Component) {
    return null;
  }

  return (
    <Component
      width={computedWidth}
      height={computedHeight}
      className={cn(style.component, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(ICON_NAME_MAP)),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

Icon.defaultProps = {
  name: null,
  size: null,
  width: null,
  height: null,
  className: null
};

export default memo(Icon);
