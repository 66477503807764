import React, {
  useMemo,
  memo
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useFormState from '../../../hooks/useFormState';
import { noopPromise, isNotEmptyArray, isEmptyObject } from '../../../utils';

import Field from '../../ui/Field';
import Text from '../../ui/Text';
import InputText from '../../ui/InputText';
import InputPassword from '../../ui/InputPassword';
import InputCheckbox from '../../ui/InputCheckbox';
import ErrorMessage from '../../ui/ErrorMessage';
import ButtonWithSpinner from '../../ui/ButtonWithSpinner';

import style from './FormLogin.module.scss';

const DATA = {
  username: '',
  password: '',
  terms: false
};

const RULES = {
  username: {
    required: true
  },
  password: {
    required: true
  },
  terms: {
    required: true
  }
};

const PrivacyPolicy = ({
  lang
}) => {
  const { t } = useTranslation();

  return (
    <div className={style.privacy}>
      <Text
        size="XS"
        className={style.privacyText}
      >
        {t('pravacyPolicyAccept')}
        <a
          href={`https://evimate.com/${lang}/user_agreement`}
          target="_blank"
          rel="noreferrer"
        >
          {t('pravacyPolicyText')}
        </a>
      </Text>
    </div>
  );
};

PrivacyPolicy.propTypes = {
  lang: PropTypes.string
};

PrivacyPolicy.defaultProps = {
  lang: 'ru'
};

const FormLogin = ({
  onSubmit,
  onSuccess,
  onFail,
  lang
}) => {
  const {
    onFormSubmit,
    onFieldChange,
    formState,
    processing,
    errors
  } = useFormState(DATA, RULES, onSubmit, onSuccess, onFail);
  const { t } = useTranslation();

  const computedError = useMemo(() => {
    if (isEmptyObject(errors)) {
      return null;
    }

    if (isNotEmptyArray(Object.keys(errors))) {
      return errors[Object.keys(errors)[0]];
    }

    return null;
  }, [errors]);

  return (
    <form
      noValidate
      onSubmit={onFormSubmit}
    >
      <Field
        label={t('username')}
        labelFor="username"
      >
        <InputText
          type="text"
          id="username"
          name="username"
          placeholder={t('usernamePlaceholder')}
          error={!!errors.username}
          disabled={processing}
          value={formState.username}
          onChange={onFieldChange}
        />
      </Field>

      <Field
        label={t('password')}
        labelFor="password"
      >
        <InputPassword
          id="password"
          name="password"
          placeholder={t('passwordPlaceholder')}
          error={!!errors.password}
          disabled={processing}
          value={formState.password}
          onChange={onFieldChange}
        />
      </Field>

      <Field>
        <InputCheckbox
          name="terms"
          label={<PrivacyPolicy lang={lang} />}
          disabled={processing}
          error={!!errors.terms}
          checked={formState.terms}
          onChange={onFieldChange}
        />
      </Field>

      {!!computedError && (
        <div className={style.nonFieldError}>
          <ErrorMessage>
            {computedError}
          </ErrorMessage>
        </div>
      )}

      <div className={style.submitButton}>
        <ButtonWithSpinner
          type="submit"
          processing={processing}
          stretch
          disabled={!formState.terms}
        >
          {t('signIn')}
        </ButtonWithSpinner>
      </div>
    </form>
  );
};

FormLogin.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  lang: PropTypes.string
};

FormLogin.defaultProps = {
  onSubmit: noopPromise,
  onSuccess: noopPromise,
  onFail: noopPromise,
  lang: 'ru'
};

export default memo(FormLogin);
