import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import { userDataSelector } from '../../store/userInfo/selectors';

import EditableUserNameInput from './EditableUserNameInput';

const userNameSelector = createSelector(
  userDataSelector,
  (data) => data?.full_name || ''
);

const mapStateToProps = createStructuredSelector({
  userName: userNameSelector
});

export default connect(mapStateToProps)(EditableUserNameInput);
