import React, {
  useState,
  useMemo,
  useCallback,
  memo
} from 'react';
import PropTypes from 'prop-types';

import InputText from '../InputText';
import Icon from '../Icon';

import style from './InputPassword.module.scss';

const InputPassword = ({
  disabled,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  const icon = useMemo(() => (visible ? 'CrossedEye' : 'Eye'), [visible]);
  const title = useMemo(() => (visible ? 'Hide password' : 'Show password'), [visible]);

  const toggleVisible = useCallback(() => {
    setVisible((prevValue) => !prevValue);
  }, []);

  return (
    <div className={style.component}>
      <InputText
        type={!visible ? 'password' : 'text'}
        className={style.input}
        disabled={disabled}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />

      {!disabled && (
        <button
          type="button"
          title={title}
          className={style.eyeButton}
          onClick={toggleVisible}
        >
          <Icon
            name={icon}
            width="22"
            height="19"
          />
        </button>
      )}
    </div>
  );
};

InputPassword.propTypes = {
  disabled: PropTypes.bool
};

InputPassword.defaultProps = {
  disabled: false
};

export default memo(InputPassword);
