import React, {
  useState,
  useCallback,
  memo
} from 'react';
import PropTypes from 'prop-types';

import Icon from '../ui/Icon';
import Text from '../ui/Text';
import FormAccount from '../forms/FormAccount';

import style from './EditableUserNameInput.module.scss';

const EditableUserNameInput = ({
  userName
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const editModeOpen = useCallback(() => setIsEdit(true), []);
  const editModeClose = useCallback(() => setIsEdit(false), []);

  return (
    <div className={style.component}>
      {!isEdit && (
        <div className={style.nonEdit}>
          <Text
            className={style.name}
          >
            {userName}
          </Text>

          <button
            type="button"
            className={style.editButton}
            onClick={editModeOpen}
          >
            <Icon
              name="Pencil"
              size="20"
            />
          </button>
        </div>
      )}

      {isEdit && (
        <div className={style.edit}>
          <FormAccount onReset={editModeClose} />
        </div>
      )}
    </div>
  );
};

EditableUserNameInput.propTypes = {
  userName: PropTypes.string
};

EditableUserNameInput.defaultProps = {
  userName: ''
};

export default memo(EditableUserNameInput);
