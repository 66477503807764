import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  ResponsiveContainer,
  AreaChart,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  Area,
  ReferenceLine
} from 'recharts';

import Text from '../../ui/Text';

import { getColorFromTemperature } from '../../../utils';

import style from './Chart.module.scss';

// const data = [
//   {
//     time: '10:00',
//     temp: 35.7
//   },
//   {
//     name: '10:30',
//     temp: 35.6
//   },
//   {
//     time: '11:00',
//     temp: 35.8
//   },
//   {
//     name: '11:30',
//     temp: 36.0
//   },
//   {
//     time: '12:00',
//     temp: 36.6
//   },
//   {
//     name: '12:30',
//     temp: 36.7
//   },
//   {
//     time: '13:00',
//     temp: 36.7
//   },
//   {
//     name: '13:30',
//     temp: 36.8
//   },
//   {
//     time: '14:00',
//     temp: 36.9
//   },
//   {
//     name: '14:30',
//     temp: 37.1
//   },
//   {
//     time: '15:00',
//     temp: 37.3
//   },
//   {
//     name: '15:30',
//     temp: 37.5
//   },
//   {
//     time: '16:00',
//     temp: 37.8
//   },
//   {
//     name: '16:30',
//     temp: 38.0
//   },
//   {
//     time: '17:00',
//     temp: 38.2
//   },
//   {
//     name: '17:30',
//     temp: 38.4
//   },
//   {
//     time: '18:00',
//     temp: 38.0
//   },
//   {
//     name: '18:30',
//     temp: 37.6
//   },
//   {
//     time: '19:00',
//     temp: 37.4
//   }
// ];

const textStyle = {
  color: '#525358',
  fontSize: '16px',
  fontWeight: '400'
};

const colorMap = {
  high: '#E38888',
  normal: '#10A7C8',
  low: '#10A7C8'
};

const chartMargin = {
  top: 0,
  right: 0,
  left: 20,
  bottom: 20
};

const TEMPERATURE_HIGH_LIMIT = 37.1;

const Dot = ({
  value, // eslint-disable-line
  cx, // eslint-disable-line
  cy // eslint-disable-line
}) => {
  const computedColor = useMemo(() => {
    let trigger = value;
    if (Array.isArray(value)) {
      trigger = value[1]; // eslint-disable-line
    }

    const temperatureClass = getColorFromTemperature(trigger);
    return colorMap[temperatureClass];
  }, [value]);
  return (
    <svg
      x={cx - 7}
      y={cy - 7}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="7"
        r="5"
        fill="white"
        stroke={computedColor}
        strokeWidth="3"
      />
    </svg>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const temperatureClass = getColorFromTemperature(payload[0].value);

    return (
      <div className={style.tooltip}>
        <Text
          thin
          className={style.tooltipLabel}
        >
          {label === '' ? payload[0].payload?.name : label}
        </Text>
        <Text
          semibold
          className={cn(style.tooltipText, style[temperatureClass])}
        >
          {payload[0].value && payload[0].value.toFixed(1)}°C
        </Text>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

CustomTooltip.defaultProps = {
  active: true,
  payload: [],
  label: ''
};

const Chart = ({
  measurements
}) => (
  <div className={style.component}>
    <ResponsiveContainer
      width="100%"
      height={363}
    >
      <AreaChart
        width={730}
        height={363}
        data={measurements}
        margin={chartMargin}
      >
        <defs>
          <linearGradient
            id="colorTemp"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="0%"
              stopColor="#F8DED4"
              stopOpacity={1}
            />
            <stop
              offset="100%"
              stopColor="#F3F0FF"
              stopOpacity={1}
            />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="time"
          tick={textStyle}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
          tickCount={24}
          interval="preserveStartEnd"
        />
        <YAxis
          dataKey="temp"
          domain={[(dataMin) => (dataMin - 0.5).toFixed(1), (dataMax) => (dataMax + 0.5).toFixed(1)]}
          axisLine={false}
          tick={textStyle}
          tickMargin={20}
          tickLine={false}
          tickCount={8}
        />
        <CartesianGrid
          vertical={false}
        />
        <Tooltip
          content={<CustomTooltip />}
          offset={10}
          cursor={false}
        />
        <Area
          type="monotone"
          dataKey="temp"
          stroke="#10A7C8"
          strokeWidth="2.5"
          fillOpacity={1}
          fill="url(#colorTemp)"
          activeDot={<Dot />}
        />
        <ReferenceLine
          y={TEMPERATURE_HIGH_LIMIT}
          stroke="#E38888"
          strokeWidth="2"
          strokeDasharray="3 3"
        />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

Chart.propTypes = {
  measurements: PropTypes.array
};

Chart.defaultProps = {
  measurements: []
};

export default memo(Chart);
