import { DateTime } from 'luxon';
import i18n from '../services/i18next';

export const noop = () => {};

export const noopPromise = () => Promise.resolve({});

export const isNotEmptyArray = (obj) => Array.isArray(obj) && obj.length > 0;

export const isNumber = (num) => (typeof num === 'number') && !Number.isNaN(num) && !Number.isFinite(num);

export const isEmptyObject = (obj) => obj
  && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const formatDateToString = (date = null) => (date ? DateTime.fromISO(date).setLocale(i18n.language || 'ru').toFormat('ff') : ''); // eslint-disable-line max-len
export const formatDateToShortString = (date = null) => (date ? DateTime.fromISO(date).setLocale(i18n.language || 'ru').toFormat('DDD') : ''); // eslint-disable-line max-len

export const getColorFromTemperature = (temperature = null) => {
  let color = 'normal';

  if (!temperature) {
    return '';
  }

  if (temperature <= 35.0) {
    color = 'low';
  } else if (temperature >= 37.1) {
    color = 'high';
  }

  return color;
};

export const getColorFromChargeLevel = (level = null) => {
  let color = 'blue';

  if (!level) {
    return color;
  }

  if (level <= 49) {
    color = 'red';
  } else if (level >= 80) {
    color = 'green';
  }

  return color;
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    const args = arguments; // eslint-disable-line

    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(this, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(this, args);
    }
  };
};

export const stopPropagation = (e) => {
  e.stopPropagation();
};

export const blobAsFile = (data, contentDisposition, contentType, extension = 'csv') => {
  const filename = contentDisposition
    ? contentDisposition?.match(/filename="(.+)"/)[1]
    : `export-${DateTime.now().toSeconds()}.${extension}`;
  const file = new Blob([data], { type: contentType });
  const href = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = href;
  link.download = filename;
  link.dispatchEvent(new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window
  }));
  link.remove();
};

export const getMaxOfArray = (numArray) => Math.max.apply(null, numArray);
export const getMinOfArray = (numArray) => Math.min.apply(null, numArray);

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${(value || '')}${expires}; path=/`;
};

export const createArray = (length, iterator = () => null) => Array.from({ length }, iterator);
