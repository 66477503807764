import React, { memo } from 'react';
import {
  Switch,
  Redirect
} from 'react-router-dom';
import PropTypes from 'prop-types';

import RedirectConditionRoute from '../../components/RedirectConditionRoute';

import SignIn from '../../pages/SignIn';
import Users from '../../pages/Users';
import UserInfo from '../../pages/UserInfo';

// eslint-disable-next-line arrow-body-style
const Router = ({ indexRedirectTo }) => {
  return (
    <Switch>
      {/* Unauthorized routes */}

      <RedirectConditionRoute
        exact
        path="/sign-in/"
        component={SignIn}
        conditions={{ authorized: false }}
      />

      {/* Authorized routes */}

      <RedirectConditionRoute
        exact
        path="/"
        component={Users}
        conditions={{ authorized: true }}
      />

      <RedirectConditionRoute
        exact
        path="/user/:id"
        component={UserInfo}
        conditions={{ authorized: true }}
      />

      <Redirect to={indexRedirectTo} />
    </Switch>
  );
};

Router.propTypes = {
  indexRedirectTo: PropTypes.string
};

Router.defaultProps = {
  indexRedirectTo: '/'
};

export default memo(Router);
