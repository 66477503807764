import React, {
  memo,
  useMemo,
  useCallback,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../../ui/Icon';
import Heading from '../../ui/Heading';
import InputSearch from '../../ui/InputSearch';
import ConfirmDelete from '../ConfirmDelete';
import ExportButton from '../../UserInfo/ExportButton';

import { noopPromise } from '../../../utils';

import style from './UsersHeader.module.scss';

const UsersHeader = ({
  onSearch,
  searchValue,
  page,
  selectedUsers,
  onUserDelete,
  getList
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const onClearHandler = useCallback(() => onSearch(''), [onSearch]);
  const isActionButtonActive = useMemo(() => selectedUsers.length !== 0, [selectedUsers]);

  const handleOpenDeleteModal = useCallback(() => setIsDeleteOpen(true), []);
  const handleCloseDeleteModal = useCallback(() => setIsDeleteOpen(false), []);

  const onDeleteConfirm = useCallback(() => {
    const promises = selectedUsers.map((user) => onUserDelete(user));
    Promise.all(promises).then(() => {
      getList(`/user/?limit=10&offset=${(page - 1) * 10}`);
      handleCloseDeleteModal();
    });
  }, [onUserDelete, selectedUsers, getList, handleCloseDeleteModal, page]);

  return (
    <div className={style.component}>
      <div className={style.left}>
        <div className={style.logo} />
        <Heading className={style.title}>
          EviMate
        </Heading>
      </div>
      <div className={style.right}>
        <InputSearch
          value={searchValue}
          onChange={onSearch}
          onClear={onClearHandler}
        />
        <div className={style.buttons}>
          <ExportButton
            type="many"
            disabled={!isActionButtonActive}
          />

          <button
            type="button"
            disabled={!isActionButtonActive}
            className={cn(style.button, {
              [style.active]: isActionButtonActive
            })}
            onClick={handleOpenDeleteModal}
          >
            <Icon
              name="Trash"
              width="15"
              height="20"
            />
          </button>
        </div>
      </div>

      <ConfirmDelete
        isOpen={isDeleteOpen}
        selectedUsers={selectedUsers}
        onClose={handleCloseDeleteModal}
        onConfirm={onDeleteConfirm}
      />
    </div>
  );
};

UsersHeader.propTypes = {
  onSearch: PropTypes.func,
  onUserDelete: PropTypes.func,
  searchValue: PropTypes.string,
  page: PropTypes.number,
  selectedUsers: PropTypes.array,
  getList: PropTypes.func
};

UsersHeader.defaultProps = {
  onSearch: noopPromise,
  onUserDelete: noopPromise,
  searchValue: '',
  page: 1,
  selectedUsers: [],
  getList: noopPromise
};

export default memo(UsersHeader);
