import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setFilter } from '../../../store/userInfo/actions';

import { filterSelector, loadingSelector } from '../../../store/userInfo/selectors';
import { langSelector } from '../../../store/app/selectors';

import DateFilter from './DateFilter';

const mapStateToProps = createStructuredSelector({
  filter: filterSelector,
  lang: langSelector,
  loading: loadingSelector
});

const mapDispatchToProps = {
  onFilterChange: setFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
