import {
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';

import { noopPromise } from '../utils';
import validate from '../utils/validate';

const EMPTY_VALUES = [undefined, null, ''];

const isEqualValues = (value1, value2) => {
  if (EMPTY_VALUES.indexOf(value1) !== -1 && EMPTY_VALUES.indexOf(value2) !== -1) {
    return true;
  }

  return value1 === value2;
};

const useFormState = (
  initialState = {},
  validationRules = {},
  onSubmit = noopPromise,
  onSuccess = noopPromise,
  onFail = noopPromise
) => {
  const [formState, setFormState] = useState(initialState);
  const [processing, setProcessing] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);

  const changed = useMemo(() => {
    const keys = Object.keys(formState);
    const changedFieldName = keys.find((key) => !isEqualValues(formState[key], initialState[key]));
    return !!changedFieldName;
  }, [formState, initialState]);

  const errors = useMemo(() => ({
    ...apiErrors,
    ...validationErrors
  }), [apiErrors, validationErrors]);

  useEffect(() => {
    setFormState(initialState);
  }, [initialState]);

  const setField = useCallback((name, value) => setFormState((state) => ({
    ...state,
    [name]: value
  })), []);

  const setForm = useCallback((data) => setFormState((state) => ({
    ...state,
    ...data
  })), []);

  const onFieldChange = useCallback((value, name) => {
    if (errors && errors[name] && errors[name].length && validationRules[name]) {
      const fieldData = { [name]: value };
      const fieldRules = { [name]: validationRules[name] };
      const validation = validate(fieldData, fieldRules);
      setValidationErrors((prev) => ({
        ...prev,
        [name]: validation.errors[name]
      }));
    }

    setFormState((state) => ({
      ...state,
      [name]: value
    }));
  }, [errors, validationRules]);

  const onFormSubmit = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }

    if (processing) {
      return;
    }

    const validation = validate(formState, validationRules);
    setApiErrors(null);
    setValidationErrors(validation.errors);

    if (!validation.valid) {
      return;
    }

    setProcessing(true);
    onSubmit(formState, changed)
      .then((action) => {
        onSuccess(action, formState);
        setFormState(initialState);
      })
      .catch((err) => {
        setApiErrors(err?.response?.data || null);
        onFail(err);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [ // eslint-disable-line
    processing,
    formState,
    changed,
    validationRules,
    onSubmit,
    onSuccess,
    onFail
  ]);

  return {
    setField,
    setForm,
    onFormSubmit,
    onFieldChange,
    formState,
    changed,
    processing,
    errors
  };
};

export default useFormState;
