import React, {
  memo,
  useEffect,
  useRef,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';

import { noop } from '../../../utils';

import style from './Modal.module.scss';

const Modal = ({
  isOpen,
  onClose,
  children
}) => {
  const modalRef = useRef(null);

  const onAfterOpen = useCallback(() => {
    disableBodyScroll(modalRef.current);
  }, []);

  const onRequestClose = useCallback(() => {
    enableBodyScroll(modalRef.current);
    onClose();
  }, [onClose]);

  const overlayRef = useCallback((element) => {
    modalRef.current = element;
  }, []);

  useEffect(() => () => {
    clearAllBodyScrollLocks();
  }, []);

  useEffect(() => () => {
    if (isOpen) {
      clearAllBodyScrollLocks();
    }
  }, [isOpen]);

  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      className={style.modal}
      overlayClassName={style.overlay}
      overlayRef={overlayRef}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
    >
      <div className={style.content}>
        {children}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
};

Modal.defaultProps = {
  isOpen: false,
  onClose: noop,
  children: null
};

export default memo(Modal);
