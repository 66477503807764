import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  loadingSelector,
  userDataSelector,
  internetSelector,
  bluetoothSelector,
  activeMeasurementSelector,
  userIdSelector
} from '../../../store/userInfo/selectors';

import UserInfoHeader from './UserInfoHeader';

const temperatureInfoSelector = createSelector(
  userDataSelector,
  (data) => (data ? data.last_measurement : {})
);

const deviceInfoSelector = createSelector(
  userDataSelector,
  (data) => (data ? data.current_device : {})
);

const mapStateToProps = createStructuredSelector({
  userDataId: userIdSelector,
  loading: loadingSelector,
  temperatureInfo: temperatureInfoSelector,
  deviceInfo: deviceInfoSelector,
  isBluetooth: bluetoothSelector,
  isInternet: internetSelector,
  isActiveMeasurement: activeMeasurementSelector
});

export default connect(mapStateToProps)(UserInfoHeader);
