import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../../ui/Text';

import style from './Empty.module.scss';

const Empty = () => {
  const { t } = useTranslation();

  return (
    <div className={style.component}>
      <div className={style.image} />

      <div className={style.block}>
        <Text className={style.text}>
          {t('noDataChart')}
        </Text>
      </div>
    </div>
  );
};

export default memo(Empty);
