import { createAction } from 'redux-actions';
import axios from 'axios';
import {
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  SET_FILTER,
  SET_SEARCH_VALUE,
  SET_SELECTED_USERS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  SET_PAGE
} from './constants';

import { filterSelector, searchSelector } from './selectors';

import {
  blobAsFile,
  isNotEmptyArray,
  getMaxOfArray,
  getMinOfArray,
  isEmptyObject
} from '../../utils';

export const getListRequest = createAction(GET_USERS_LIST);
export const getListSuccess = createAction(GET_USERS_LIST_SUCCESS);

const mapFilterToValue = {
  gte: {
    high: 37.1,
    normal: 35.1,
    low: null
  },
  lt: {
    high: null,
    normal: 37.1,
    low: 35.1
  }
};

const allTemperatureValues = {
  last_measurement_value_gte: -100,
  last_measurement_value_lt: 100
};

export const getList = (link = null, search = null) => (dispatch, getState) => {
  const filter = filterSelector(getState());
  const storeSearch = searchSelector(getState());
  const filterQueryObject = filter.reduce((acc, current) => ({
    gte: [...acc.gte, mapFilterToValue.gte[current]],
    lt: [...acc.lt, mapFilterToValue.lt[current]]
  }), {
    gte: [],
    lt: []
  });

  const profiledFilterQueryObject = {
    gte: filterQueryObject.gte?.length === 0 || filterQueryObject.gte?.includes(null) ? null : getMinOfArray(filterQueryObject.gte), // eslint-disable-line
    lt: filterQueryObject.lt?.length === 0 || filterQueryObject.lt?.includes(null) ? null : getMaxOfArray(filterQueryObject.lt) // eslint-disable-line
  };
  const resultFilterQueryObject = {};
  if (profiledFilterQueryObject.gte !== null) {
    resultFilterQueryObject.last_measurement_value_gte = profiledFilterQueryObject.gte;
  }
  if (profiledFilterQueryObject.lt !== null) {
    resultFilterQueryObject.last_measurement_value_lt = profiledFilterQueryObject.lt;
  }

  const filterQueryParams = isNotEmptyArray(filter) ? new URLSearchParams(isEmptyObject(resultFilterQueryObject) && filter.length > 0 ? allTemperatureValues : resultFilterQueryObject)?.toString() : ''; // eslint-disable-line

  dispatch(getListRequest());
  const params = link && link.split('/user/')[1];
  const urlLink = params ? `user/${params}&${filterQueryParams}` : `user/?limit=10&${filterQueryParams}`;
  const searchLink = `user/?search=${storeSearch !== '' ? storeSearch : search}&limit=10`;
  return axios.get((storeSearch || search) ? searchLink : urlLink).then(({ data }) => {
    dispatch(getListSuccess(data));
  });
};

export const deleteUserRequest = createAction(DELETE_USER);
export const deleteUserSuccess = createAction(DELETE_USER_SUCCESS);

export const deleteUser = (id) => (dispatch) => {
  dispatch(deleteUserRequest());
  return axios.delete(`user/${id}/`).then(() => {
    dispatch(deleteUserSuccess(id));
  });
};

export const setFilter = createAction(SET_FILTER);
export const setSearchValue = createAction(SET_SEARCH_VALUE);
export const setSelectedUsers = createAction(SET_SELECTED_USERS);
export const setPage = createAction(SET_PAGE);

export const exportUsers = (users, extension = 'csv') => () => axios.post('user/export/', {
  extension,
  users
}, {
  responseType: 'blob'
}).then((res) => {
  const contentDisposition = res.headers['content-disposition'];
  const contentType = res.headers['content-type'];
  blobAsFile(res.data, contentDisposition, contentType, extension);
});
