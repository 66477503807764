import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import Chart from '../../components/UserInfo/Chart';
import UserInfoHeader from '../../components/UserInfo/UserInfoHeader';
import Empty from '../../components/UserInfo/Empty';
import DateFilter from '../../components/UserInfo/DateFilter';
import UserInfoTable from '../../components/UserInfo/UserInfoTable';
import ExportButton from '../../components/UserInfo/ExportButton';
import LineSpinner from '../../components/ui/LineSpinner';

import { noopPromise, noop } from '../../utils';
import { closeSocket } from '../../store/socketConnection';

import style from './UserInfo.module.scss';

const UserInfo = ({
  onMount,
  getUserMeasurements,
  match,
  openSocket,
  filter,
  loading,
  noMeasurements,
  updateUserStatuses,
  setFilter
}) => {
  const isNeedQuery = useRef(false);
  useEffect(() => {
    const userId = match.params.id;
    if (!userId) {
      return;
    }
    onMount(userId).then((data) => {
      const lastMeasurementTime = data?.last_measurement.timestamp;
      const newFilter = lastMeasurementTime ? DateTime.fromISO(lastMeasurementTime).toISODate() : DateTime.now().toISODate(); // eslint-disable-line
      setFilter(newFilter);
      getUserMeasurements(userId).then(() => {
        isNeedQuery.current = true;
      });

      const deviceId = data?.current_device?.id;
      if (!deviceId) {
        return;
      }
      openSocket(deviceId);
    });

    return () => { // eslint-disable-line
      closeSocket();
    };
  }, [onMount, match.params.id, openSocket, setFilter, getUserMeasurements]);

  useEffect(() => {
    const userId = match.params.id;
    if (!userId) {
      return;
    }

    if (!isNeedQuery.current) {
      return;
    }

    getUserMeasurements(userId);
  }, [match.params.id, filter, getUserMeasurements]);

  useEffect(() => {
    updateUserStatuses();
    const statusCheckerInterval = setInterval(updateUserStatuses, 10000);

    return () => {
      clearInterval(statusCheckerInterval);
    };
  }, [updateUserStatuses]);

  const userId = match.params.id;
  const isOverlayVisible = loading;

  return (
    <div className={style.component}>
      <div className={style.container}>
        <UserInfoHeader userId={userId} />
        <div className={style.dilimiter} />
        <div className={style.content}>
          {isOverlayVisible && (
            <div className={style.overlay}>
              <LineSpinner
                size={32}
                duration={750}
              />
            </div>
          )}
          {!noMeasurements && <Chart />}
          {!loading && noMeasurements && <Empty />}
        </div>
        <div className={style.filter}>
          <DateFilter />

          <div className={style.exportButton}>
            <ExportButton />
          </div>
        </div>
        {!noMeasurements && (
          <div className={style.table}>
            <UserInfoTable />
          </div>
        )}
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  onMount: PropTypes.func,
  getUserMeasurements: PropTypes.func,
  match: PropTypes.object,
  openSocket: PropTypes.func,
  filter: PropTypes.string,
  updateUserStatuses: PropTypes.func,
  loading: PropTypes.bool,
  noMeasurements: PropTypes.bool,
  setFilter: PropTypes.func
};

UserInfo.defaultProps = {
  onMount: noopPromise,
  getUserMeasurements: noopPromise,
  match: {},
  openSocket: noopPromise,
  filter: null,
  updateUserStatuses: noop,
  loading: false,
  noMeasurements: false,
  setFilter: noop
};

export default memo(UserInfo);
