import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Button.module.scss';

const Button = ({
  type,
  theme,
  size,
  stretch,
  children,
  ...props
}) => (
  <button
    type={type} // eslint-disable-line react/button-has-type
    className={cn(
      style[`theme__${theme}`],
      style[`size__${size}`],
      {
        [style.stretch]: stretch
      }
    )}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset'
  ]),
  theme: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger'
  ]),
  size: PropTypes.oneOf([
    'S',
    'M',
    'L'
  ]),
  stretch: PropTypes.bool,
  children: PropTypes.node
};

Button.defaultProps = {
  type: 'button',
  theme: 'primary',
  size: 'M',
  stretch: false,
  children: null
};

export default memo(Button);
