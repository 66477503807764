import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import UsersTable from '../../components/Users/UsersTable';
import UsersHeader from '../../components/Users/UsersHeader';
import Empty from '../../components/Users/Empty';

import { noopPromise } from '../../utils';

import style from './Users.module.scss';

const Users = ({
  onMount,
  loading,
  noUsers
}) => {
  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={style.component}>
      <div className={style.container}>
        <UsersHeader />
        <div className={style.dilimiter} />
        {!loading && noUsers && <Empty />}
        {!noUsers && <UsersTable />}
      </div>
    </div>
  );
};

Users.propTypes = {
  onMount: PropTypes.func,
  loading: PropTypes.bool,
  noUsers: PropTypes.bool
};

Users.defaultProps = {
  onMount: noopPromise,
  loading: false,
  noUsers: false
};

export default memo(Users);
