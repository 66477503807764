import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  usersListSelector,
  usersCountSelector,
  selectedUsersSelector,
  loadingSelector,
  pageSelector
} from '../../../store/users/selectors';

import { setSelectedUsers, setPage } from '../../../store/users/actions';

import UsersTable from './UsersTable';

const mapStateToProps = createStructuredSelector({
  users: usersListSelector,
  count: usersCountSelector,
  selectedUsers: selectedUsersSelector,
  loading: loadingSelector,
  page: pageSelector
});

const mapDispatchToProps = {
  setSelectedUsers,
  setPage
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
