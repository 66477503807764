import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import Icon from '../../ui/Icon';
import Text from '../../ui/Text';
import BackButton from '../BackButton';
import StatusBar from '../StatusBar';
import EditableUserNameInput from '../../EditableUserNameInput';

import { formatDateToString } from '../../../utils';

import style from './UserInfoHeader.module.scss';

const skeletonStylesBig = {
  borderRadius: '4px',
  lineHeight: '58px'
};

const skeletonStylesMedium = {
  borderRadius: '4px',
  lineHeight: '43px'
};

const skeletonStylesSmall = {
  borderRadius: '4px',
  lineHeight: '16px'
};

const UserInfoHeader = ({
  temperatureInfo,
  deviceInfo,
  isActiveMeasurement,
  isBluetooth,
  isInternet,
  loading,
  userDataId,
  userId
}) => {
  const { t } = useTranslation();

  const isSkeletonVisible = loading && userDataId !== userId;

  return (
    <div className={style.component}>
      <div className={style.left}>
        <div className={style.backButton}>
          <BackButton />
        </div>
        <div className={style.userInfo}>
          <div className={style.onlineIndicator}>
            <div className={style.icon}>
              <Icon
                name={isActiveMeasurement ? 'Online' : 'Offline'}
                size="24"
              />
            </div>

            {isActiveMeasurement && <div className={style.pulse} />}
          </div>

          <div className={style.editableName}>
            {!isSkeletonVisible ? <EditableUserNameInput /> : (
              <Skeleton
                baseColor="#F7F9FB"
                style={skeletonStylesMedium}
              />
            )}
          </div>
        </div>
      </div>
      <div className={style.right}>
        <div className={style.updatedAt}>
          {!isSkeletonVisible ? (
            <Text
              thin
              className={style.updatedAtText}
            >
              {t('updatedAt')} {temperatureInfo.timestamp ? formatDateToString(temperatureInfo.timestamp) : '?'}
            </Text>
          ) : (
            <Skeleton
              baseColor="#F7F9FB"
              style={skeletonStylesSmall}
            />
          )}
        </div>
        <div className={style.tips}>
          <div className={style.connect}>
            <StatusBar
              bluetooth={isBluetooth}
              internet={isInternet}
              chargeLevel={deviceInfo.battery_level}
            />
          </div>
          <div className={style.temperature}>
            {!isSkeletonVisible ? (
              <Text className={style.temperatureText}>
                {temperatureInfo.value ? temperatureInfo.value.toFixed(1) : '?'}°C
              </Text>
            ) : (
              <Skeleton
                baseColor="#F7F9FB"
                style={skeletonStylesBig}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UserInfoHeader.propTypes = {
  temperatureInfo: PropTypes.object,
  deviceInfo: PropTypes.object,
  isActiveMeasurement: PropTypes.bool,
  isBluetooth: PropTypes.bool,
  isInternet: PropTypes.bool,
  loading: PropTypes.bool,
  userDataId: PropTypes.string,
  userId: PropTypes.string
};

UserInfoHeader.defaultProps = {
  temperatureInfo: {},
  deviceInfo: {},
  isActiveMeasurement: true,
  isBluetooth: true,
  isInternet: true,
  loading: false,
  userDataId: null,
  userId: null
};

export default memo(UserInfoHeader);
