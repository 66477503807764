import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { getColorFromChargeLevel } from '../../utils';

import style from './Battery.module.scss';

const Battery = ({
  level
}) => {
  const color = useMemo(() => getColorFromChargeLevel(level), [level]);
  const strokeWidth = useMemo(() => {
    const k = 0.14;
    return level ? k * level : 6;
  }, [level]);
  /* eslint-disable */
  return (
    <div className={cn(style.component, style[color])}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 15.537V14.7C20 14.1477 20.4477 13.7 21 13.7V13.7C21.5523 13.7 22 13.2523 22 12.7V10.3C22 9.74772 21.5523 9.3 21 9.3V9.3C20.4477 9.3 20 8.85228 20 8.3V7.463C20 6.66 19.4 6 18.67 6L3.34 6C2.6 6 2 6.66 2 7.463V15.526C2 16.34 2.6 17 3.33 17L18.67 17C19.4 17 20 16.34 20 15.537Z" stroke="#10A7C8" strokeWidth="2" />
        <rect
          x="4"
          y="8"
          width={strokeWidth}
          height="7"
          rx="1"
          fill="#10A7C8"
        />
      </svg>
    </div>
  );
  /* eslint-enable */
};

Battery.propTypes = {
  level: PropTypes.number
};

Battery.defaultProps = {
  level: null
};

export default memo(Battery);
