import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { authorizedSelector } from '../../store/auth/selectors';
import { indexPathSelector } from '../../store/app/selectors';

import RedirectConditionRoute from './RedirectConditionRoute';

const conditionsSlice = (_, { conditions }) => conditions || null;

const redirectSelector = createSelector(
  authorizedSelector,
  conditionsSlice,
  (authorized, conditions) => {
    if (!conditions) {
      return false;
    }

    if (typeof conditions.authorized === 'boolean' && authorized !== conditions.authorized) {
      return true;
    }

    return false;
  }
);

const mapStateToProps = (state, props) => ({
  redirect: redirectSelector(state, props),
  redirectTo: indexPathSelector(state)
});

export default connect(mapStateToProps)(RedirectConditionRoute);
