import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import Router from './Router';

const slice = ({ auth }) => auth;

const authorizedSelector = createSelector(slice, ({ authorized }) => authorized);

const indexPathSelector = createSelector(
  authorizedSelector,
  (authorized) => (
    !authorized ? '/sign-in/' : '/'
  )
);

const mapStateToProps = createStructuredSelector({
  indexRedirectTo: indexPathSelector
});

export default connect(mapStateToProps)(Router);
