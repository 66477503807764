import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Text from '../../ui/Text';
import Icon from '../../ui/Icon';

import style from './BackButton.module.scss';

const BackButton = () => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    push('/');
  }, [push]);

  return (
    <button
      type="button"
      className={style.component}
      onClick={onClick}
    >
      <Icon
        name="Arrow"
        width="9"
        height="16"
      />

      <div className={style.block}>
        <Text
          semibold
          size="XS"
          className={style.text}
        >
          {t('toUserList')}
        </Text>
      </div>
    </button>
  );
};

export default memo(BackButton);
