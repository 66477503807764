import React, { memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import Text from '../../ui/Text';
import InputCheckbox from '../../ui/InputCheckbox';
import Pagination from './Pagination';
import Battery from '../../Battery';
import Filter from './Filter';

import {
  formatDateToString,
  getColorFromTemperature,
  noopPromise,
  stopPropagation,
  noop
} from '../../../utils';

import style from './UsersTable.module.scss';

const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const skeletonStyles = {
  borderRadius: '2px',
  lineHeight: '32px'
};

const SkeletonRows = () => rows.map((_, idx) => (
  <tr
    key={idx} // eslint-disable-line
  >
    <td
      align="center"
      className={style.skeletonCol}
    >
      <Skeleton
        baseColor="#F7F9FB"
        style={skeletonStyles}
      />
    </td>
    <td className={style.skeletonCol}>
      <Skeleton
        baseColor="#F7F9FB"
        style={skeletonStyles}
      />
    </td>
    <td className={style.skeletonCol}>
      <Skeleton
        baseColor="#F7F9FB"
        style={skeletonStyles}
      />
    </td>
    <td className={style.skeletonCol}>
      <Skeleton
        baseColor="#F7F9FB"
        style={skeletonStyles}
      />
    </td>
    <td className={style.skeletonCol}>
      <Skeleton
        baseColor="#F7F9FB"
        style={skeletonStyles}
      />
    </td>
    <td
      align="center"
      className={style.skeletonCol}
    >
      <InputCheckbox disabled />
    </td>
  </tr>
));

const UsersTable = ({
  users,
  count,
  selectedUsers,
  setSelectedUsers,
  loading,
  page,
  setPage
}) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const selectedUsersCount = selectedUsers.length;

  const onUserCheckboxClick = useCallback((id, checked) => {
    let payload = [];
    if (selectedUsers.includes(id) && !checked) {
      payload = selectedUsers.filter((p) => p !== id);
    } else {
      payload = checked ? [...selectedUsers, id] : selectedUsers;
    }

    setSelectedUsers(payload);
  }, [setSelectedUsers, selectedUsers]);

  const toggleSelectAllUsers = useCallback((checked) => {
    let payload = [];
    if (checked) {
      payload = users.map((u) => u.id);
    }

    setSelectedUsers(payload);
  }, [users, setSelectedUsers]);

  const onUserClick = useCallback((id) => {
    push(`/user/${id}/`);
  }, [push]);

  useEffect(() => {
    setPage(1);
  }, [setPage]);

  return (
    <div className={style.wrapper}>
      <div className={style.component}>
        <table className={style.table}>
          <thead className={style.header}>
            <tr>
              <td
                align="center"
                className={style.columnNumber}
              >
                <Text semibold>
                  #
                </Text>
              </td>
              <td className={style.columnName}>
                <Text semibold>
                  {t('nameOrServiceNumber')}
                </Text>
              </td>
              <td className={style.columnTemperature}>
                <div className={style.filter}>
                  <Text semibold>
                    {t('temperature')}
                  </Text>

                  <Filter />
                </div>
              </td>
              <td className={style.columnDevice}>
                <Text semibold>
                  {t('deviceUID')}
                </Text>
              </td>
              <td className={style.columnCharge}>
                <Text semibold>
                  {t('charge')}
                </Text>
              </td>
              <td
                align="center"
                className={style.columnNumber}
              >
                <InputCheckbox
                  disabled={loading}
                  checked={selectedUsersCount > 0}
                  onChange={toggleSelectAllUsers}
                />
              </td>
            </tr>
          </thead>
          <tbody className={style.body}>
            {!loading && users.map((user, idx) => {
              const isUserSelected = selectedUsers.includes(user.id);
              const onUserSelect = (checked) => onUserCheckboxClick(user.id, checked);
              const temperatureClass = getColorFromTemperature(user.last_measurement.value);
              const userIndex = idx + 1 + ((page - 1) * 10);

              return (
                <tr
                  key={user.id}
                  className={cn(style.row, {
                    [style.selected]: isUserSelected
                  })}
                  onClick={() => onUserClick(user.id)} // eslint-disable-line
                >
                  <td align="center">
                    <Text
                      thin
                      size="S"
                    >
                      {userIndex}
                    </Text>
                  </td>
                  <td>
                    {user.full_name && (
                      <Text
                        thin
                        size="S"
                      >
                        {user.full_name}
                      </Text>
                    )}
                    {!user.full_name && (
                      <Text
                        thin
                        size="S"
                        className={style.noData}
                      >
                        {t('noData')}
                      </Text>
                    )}
                  </td>
                  <td>
                    {user.last_measurement.value && (
                      <Text
                        semibold
                        size="S"
                        className={cn(style.temperature, style[temperatureClass])}
                      >
                        {user.last_measurement.value.toFixed(1)} °C
                      </Text>
                    )}
                    {user.last_measurement.timestamp && (
                      <Text
                        thin
                        size="S"
                        className={style[temperatureClass]}
                      >
                        ({formatDateToString(user.last_measurement.timestamp)})
                      </Text>
                    )}
                    {!user.last_measurement.value && !user.last_measurement.timestamp && (
                      <Text
                        thin
                        size="S"
                        className={style.noData}
                      >
                        {t('noData')}
                      </Text>
                    )}
                  </td>
                  <td>
                    {user.current_device.id && (
                      <Text
                        thin
                        size="S"
                      >
                        {user.current_device.id}
                      </Text>
                    )}
                    {!user.current_device.id && (
                      <Text
                        thin
                        size="S"
                        className={style.noData}
                      >
                        -
                      </Text>
                    )}
                  </td>
                  <td>
                    {user.current_device.battery_level && (
                      <div className={style.battery}>
                        <Battery level={user.current_device.battery_level} />
                        <Text
                          thin
                          size="S"
                          className={style.batteryText}
                        >
                          {user.current_device.battery_level}%
                        </Text>
                      </div>
                    )}
                    {!user.current_device.battery_level && (
                      <Text
                        thin
                        size="S"
                        className={style.noData}
                      >
                        -
                      </Text>
                    )}
                  </td>
                  <td align="center">
                    <div // eslint-disable-line
                      onClick={stopPropagation}
                    >
                      <InputCheckbox
                        disabled={loading}
                        checked={isUserSelected}
                        className={style.lightCheckbox}
                        onChange={onUserSelect} // eslint-disable-line
                      />
                    </div>
                  </td>
                </tr>
              );
            })}

            {loading && count !== 0 && (
              <SkeletonRows />
            )}
          </tbody>
        </table>
      </div>

      <div className={style.pagination}>
        <Pagination
          count={count}
          selectedUsersCount={selectedUsersCount}
        />
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  users: PropTypes.array,
  count: PropTypes.number,
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
  loading: PropTypes.bool,
  page: PropTypes.number,
  setPage: PropTypes.func
};

UsersTable.defaultProps = {
  users: [],
  count: null,
  selectedUsers: [],
  setSelectedUsers: noopPromise,
  loading: false,
  page: 1,
  setPage: noop
};

export default memo(UsersTable);
