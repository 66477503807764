import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getList,
  setSearchValue,
  deleteUser
} from '../../../store/users/actions';

import {
  searchSelector,
  loadingSelector,
  selectedUsersSelector,
  pageSelector
} from '../../../store/users/selectors';

import { debounce } from '../../../utils';

import UsersHeader from './UsersHeader';

const mapStateToProps = createStructuredSelector({
  searchValue: searchSelector,
  loading: loadingSelector,
  selectedUsers: selectedUsersSelector,
  page: pageSelector
});

const debouncedSearchList = debounce((search, dispatch) => dispatch(getList(null, search)), 250);

const mapDispatchToProps = {
  onSearch: (search = null) => (dispatch) => {
    dispatch(setSearchValue(search));
    return debouncedSearchList(search, dispatch);
  },
  onUserDelete: (id) => (dispatch) => dispatch(deleteUser(id)),
  getList
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersHeader);
