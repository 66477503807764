import React, {
  memo,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import i18n from '../../services/i18next';

import Text from '../ui/Text';
import Icon from '../ui/Icon';
import Dropdown from '../ui/Dropdown';

import { noopPromise } from '../../utils';

import style from './LanguageSwitch.module.scss';

const langs = ['ru', 'en'];

const LanguageSwitch = ({
  lang,
  setLang
}) => {
  const dropdownRef = useRef(null);
  const options = langs.filter((l) => lang !== l);

  return (
    <div className={style.component}>
      <Dropdown
        ref={dropdownRef}
        contentMargin={0}
        trigger={(
          <div
            className={style.trigger}
            activeclass={style.triggerActive}
          >
            <Text
              semibold
              className={style.langText}
            >
              {lang}
            </Text>

            <Icon
              name="Arrow"
              width="8"
              height="12"
              className={style.arrowIcon}
            />
          </div>
        )}
      >
        <div className={style.content}>
          {options.map((opt, idx) => {
            const onLangClick = (value) => {
              setLang(value);
              i18n.changeLanguage(value);
              dropdownRef.current?.closeDropdown();
            };
            return (
              <div // eslint-disable-line
                key={idx} // eslint-disable-line
                className={style.option}
                onClick={() => onLangClick(opt)} // eslint-disable-line
              >
                <Text
                  semibold
                  className={style.langText}
                >
                  {opt}
                </Text>
              </div>
            );
          })}
        </div>
      </Dropdown>
    </div>
  );
};

LanguageSwitch.propTypes = {
  lang: PropTypes.string,
  setLang: PropTypes.func
};

LanguageSwitch.defaultProps = {
  lang: null,
  setLang: noopPromise
};

export default memo(LanguageSwitch);
