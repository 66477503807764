export const GET_USER_INFO = 'userInfo/GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'userInfo/GET_USER_INFO_SUCCESS';
export const SET_FILTER = 'userInfo/SET_FILTER';
export const SET_SEARCH_VALUE = 'userInfo/SET_SEARCH_VALUE';
export const SET_SELECTED_USERS = 'userInfo/SET_SELECTED_USERS';
export const UPDATE_USER_INFO = 'userInfo/UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCESS = 'userInfo/UPDATE_USER_INFO_SUCCESS';
export const GET_USER_MEASUREMENTS_SUCCESS = 'userInfo/GET_USER_MEASUREMENTS_SUCCESS';
export const UPDATE_USER_MEASUREMENTS = 'userInfo/UPDATE_USER_MEASUREMENTS';
export const UPDATE_BATTERY_LEVEL = 'userInfo/UPDATE_BATTERY_LEVEL';
export const UPDATE_USER_STATUSES = 'userInfo/UPDATE_USER_STATUSES';
