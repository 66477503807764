import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '../../ui/Text';
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';

import style from './ConfirmDelete.module.scss';
import { noop, noopPromise } from '../../../utils';

const ConfirmDelete = ({
  isOpen,
  onClose,
  selectedUsers,
  onConfirm,
  users
}) => {
  const { t } = useTranslation();
  const computedDeleteQuestionText = useMemo(
    () => (selectedUsers.length > 1
      ? `${t('deleteSeveralUserQuestion')} ${selectedUsers.length} ${t('users')}?`
      : `${t('deleteOneUserQuestion')} "${users?.find((u) => u.id === selectedUsers[0])?.full_name}"?`),
    [selectedUsers, users, t]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={style.content}>
        <div className={style.title}>
          <Text bold>
            {t('deleteUser')}
          </Text>
        </div>

        <div className={style.info}>
          <Text
            size="XS"
            thin
          >
            {computedDeleteQuestionText}
          </Text>
        </div>

        <div className={style.notion}>
          <Text
            size="XS"
            thin
          >
            {t('deleteTip')}
          </Text>
        </div>

        <div className={style.buttons}>
          <Button
            size="S"
            onClick={onConfirm}
          >
            {t('accept')}
          </Button>
          <Button
            size="S"
            theme="secondary"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmDelete.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedUsers: PropTypes.array,
  onConfirm: PropTypes.func,
  users: PropTypes.array
};

ConfirmDelete.defaultProps = {
  isOpen: false,
  onClose: noop,
  selectedUsers: [],
  onConfirm: noopPromise,
  users: []
};

export default memo(ConfirmDelete);
