import i18n from '../services/i18next';
import { isNumber } from './index';

const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
const PHONE_REGEXP = /^\+?([0-9]){1,3}([\s-]?)\(?([0-9]{3})\)?([\s-]?)([0-9]{3})([\s-]?)([0-9]{2})([\s-]?)([0-9]{2})$/; // eslint-disable-line max-len
const URL_REGEXP = /^https?:\/\/((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i; // eslint-disable-line max-len

const getDefaultError = () => ({
  required: i18n.t('requiredField'),
  email: i18n.t('enterValidEmail'),
  phone: i18n.t('enterValidPhone'),
  url: i18n.t('enterValidURL'),
  min: (minValue) => `${i18n.t('minValid')} ${minValue}`,
  max: (maxValue) => `${i18n.t('maxValid')} ${maxValue}`
});

const getErrorText = (type, ...args) => {
  const defaultErrors = getDefaultError();
  const error = defaultErrors[type];

  if (typeof error === 'function') {
    return error(...args) || i18n.t('error');
  }

  return error || i18n.t('error');
};

export const validateEmail = (str) => (typeof str === 'string') && EMAIL_REGEXP.test(str);

export const validatePhone = (str) => (typeof str === 'string') && PHONE_REGEXP.test(str);

export const validateUrl = (str) => (typeof str === 'string') && URL_REGEXP.test(str);

export const validateMin = (num, min) => num >= min;

export const validateMax = (num, max) => num <= max;

const validate = (fields, rules) => {
  const result = {
    valid: true,
    errors: {}
  };

  Object.keys(fields).forEach((key) => {
    const value = fields[key];
    const {
      required,
      email,
      phone,
      url,
      min,
      max,
      custom
    } = rules[key] || {};
    const errors = [];

    if (required) {
      if ((!value && value !== 0) || (Array.isArray(value) && !value.length)) {
        errors.push(getErrorText('required'));
      }
    }

    if (min && isNumber(value) && !validateMin(value, min)) {
      errors.push(getErrorText('min', min));
    }

    if (max && isNumber(value) && !validateMax(value, max)) {
      errors.push(getErrorText('max', max));
    }

    if (email && value && !validateEmail(value)) {
      errors.push(getErrorText('email'));
    }

    if (phone && value && !validatePhone(value)) {
      errors.push(getErrorText('phone'));
    }

    if (url && value && !validateUrl(value)) {
      errors.push(getErrorText('url'));
    }

    if (typeof custom === 'function') {
      const cError = custom(value, fields, result);

      if (cError) {
        errors.push(cError);
      }
    }

    if (errors.length) {
      result.valid = false;
      result.errors[key] = errors;
    }
  });

  return result;
};

export default validate;
