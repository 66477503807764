import { createAction } from 'redux-actions';
import axios from 'axios';
import { DateTime } from 'luxon';
import {
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  SET_FILTER,
  SET_SEARCH_VALUE,
  SET_SELECTED_USERS,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
  GET_USER_MEASUREMENTS_SUCCESS,
  UPDATE_USER_MEASUREMENTS,
  UPDATE_BATTERY_LEVEL,
  UPDATE_USER_STATUSES
} from './constants';
import { blobAsFile } from '../../utils';

export const getUserRequest = createAction(GET_USER_INFO);
export const getUserSuccess = createAction(GET_USER_INFO_SUCCESS);

export const getUser = (id) => (dispatch) => {
  dispatch(getUserRequest());
  return axios.get(`/user/${id}/`).then(({ data }) => {
    dispatch(getUserSuccess(data));
    return data;
  });
};

export const updateUserRequest = createAction(UPDATE_USER_INFO);
export const updateUserSuccess = createAction(UPDATE_USER_INFO_SUCCESS);

export const updateUser = (id, newData) => (dispatch) => {
  dispatch(updateUserRequest());
  return axios.patch(`/user/${id}/`, newData).then(({ data }) => {
    dispatch(updateUserSuccess(data));
  });
};

export const setFilter = createAction(SET_FILTER);
export const setSearchValue = createAction(SET_SEARCH_VALUE);
export const setSelectedUsers = createAction(SET_SELECTED_USERS);
export const updateUserMeasurements = createAction(UPDATE_USER_MEASUREMENTS);

export const getUserMeasurementsSuccess = createAction(GET_USER_MEASUREMENTS_SUCCESS);
export const updateBatteryLevel = createAction(UPDATE_BATTERY_LEVEL);

export const getUserMeasurements = (id, date = DateTime.now().toISODate()) => (dispatch) => {
  dispatch(getUserRequest());
  const requestDateFilter = date ?? DateTime.now().toISODate();
  return axios.get(`/user/${id}/measurements/`, { // eslint-disable-line
    params: {
      date: requestDateFilter
    }
  }).then(({ data }) => {
    dispatch(getUserMeasurementsSuccess(data));
  });
};

export const updateUserStatuses = createAction(UPDATE_USER_STATUSES);

export const exportUser = ({
  id,
  extension,
  startDate, endDate
}) => () => axios.post(`user/${id}/export/`, {
  extension,
  start_date: startDate,
  end_date: endDate
}, {
  responseType: 'blob'
}).then((res) => {
  const contentDisposition = res.headers['content-disposition'];
  const contentType = res.headers['content-type'];
  blobAsFile(res.data, contentDisposition, contentType, extension);
});
