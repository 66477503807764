import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  getList,
  setFilter,
  setSelectedUsers,
  setPage
} from '../../../../store/users/actions';

import {
  usersNextLinkSelector,
  usersPrevLinkSelector,
  loadingSelector,
  filterSelector,
  selectedUsersSelector,
  pageSelector
} from '../../../../store/users/selectors';

import Pagination from './Pagination';

const isSelectedUsers = createSelector(
  selectedUsersSelector,
  (users) => users.length > 0
);

const mapStateToProps = createStructuredSelector({
  next: usersNextLinkSelector,
  prev: usersPrevLinkSelector,
  loading: loadingSelector,
  filter: filterSelector,
  isSelectedUsers,
  page: pageSelector
});

const mapDispatchToProps = {
  getNewData: (link) => (dispatch) => dispatch(getList(link)),
  onResetFilter: () => (dispatch) => {
    dispatch(setFilter([]));
    dispatch(setPage(1));

    return dispatch(getList());
  },
  onResetUsersSelect: () => (dispatch) => dispatch(setSelectedUsers([])),
  setPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
