import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import Label from '../Label';
import ErrorMessage from '../ErrorMessage';

import { isNotEmptyArray } from '../../../utils';

import style from './Field.module.scss';

const Field = ({
  label,
  labelFor,
  children,
  error
}) => {
  const computedError = useMemo(() => {
    if (!error) {
      return null;
    }

    if (typeof error === 'string') {
      return error;
    }

    if (isNotEmptyArray(error)) {
      return error[0];
    }

    return null;
  }, [error]);

  return (
    <div className={style.component}>
      {!!label && (
        <Label htmlFor={labelFor}>
          {label}
        </Label>
      )}

      {children}

      {!!computedError && (
        <div className={style.error}>
          <ErrorMessage>
            {computedError}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
};

Field.propTypes = {
  label: PropTypes.node,
  labelFor: PropTypes.string,
  error: PropTypes.node,
  children: PropTypes.node
};

Field.defaultProps = {
  label: null,
  labelFor: null,
  error: null,
  children: null
};

export default memo(Field);
