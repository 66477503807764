import { DateTime } from 'luxon';

import { createReducer } from '../utils';
import {
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  SET_FILTER,
  SET_SEARCH_VALUE,
  UPDATE_USER_INFO_SUCCESS,
  GET_USER_MEASUREMENTS_SUCCESS,
  UPDATE_USER_MEASUREMENTS,
  UPDATE_BATTERY_LEVEL,
  UPDATE_USER_STATUSES
} from './constants';

const initialState = {
  data: null,
  loading: false,
  filter: DateTime.now().toISODate(),
  measurements: [],
  isActiveMeasurement: true,
  isBluetooth: true,
  isInternet: true
};

const LAST_MEASUREMENT_TIMEOUT = 20;

const auth = createReducer(initialState, {
  [GET_USER_INFO]: (state) => ({
    ...state,
    loading: true
  }),

  [GET_USER_INFO_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    loading: false
  }),

  [SET_FILTER]: (state, { payload }) => ({
    ...state,
    filter: payload
  }),

  [SET_SEARCH_VALUE]: (state, { payload }) => ({
    ...state,
    search: payload
  }),

  [UPDATE_USER_INFO_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload
  }),

  [GET_USER_MEASUREMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    measurements: payload,
    loading: false
  }),

  [UPDATE_USER_MEASUREMENTS]: (state, { payload }) => {
    const { filter } = state;
    const mappedData = payload.map((value) => ({
      ...value,
      timestamp: +value.timestamp
    }));
    const filteredNewValues = mappedData.filter(({ timestamp }) => DateTime.fromSeconds(timestamp).toISODate() === filter); // eslint-disable-line

    const sortedNewValuesByTime = mappedData.sort((x, y) => y.timestamp - x.timestamp);
    if (!sortedNewValuesByTime[0]) {
      return {
        ...state,
        measurements: [
          ...state.measurements,
          ...filteredNewValues.map((value) => ({
            ...value,
            timestamp: DateTime.fromSeconds(value.timestamp).toISO()
          }))
        ]
      };
    }

    return {
      ...state,
      data: {
        ...state.data,
        last_measurement: {
          ...sortedNewValuesByTime[0],
          timestamp: DateTime.fromSeconds(sortedNewValuesByTime[0].timestamp).toISO()
        }
      },
      measurements: [
        ...state.measurements,
        ...filteredNewValues.map((value) => ({
          ...value,
          timestamp: DateTime.fromSeconds(value.timestamp).toISO()
        }))
      ]
    };
  },

  [UPDATE_BATTERY_LEVEL]: (state, { payload }) => {
    if (!payload) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        current_device: {
          ...state.data.current_device,
          battery_level: payload
        }
      },
      isBluetooth: true
    };
  },

  [UPDATE_USER_STATUSES]: (state) => {
    const lastMeasurementTime = state.data?.last_measurement?.timestamp || null;
    const absoluteDiffFromDateToNow = Math.abs(DateTime.fromISO(lastMeasurementTime).diffNow().as('seconds'));

    const isActiveMeasurement = lastMeasurementTime
      ? absoluteDiffFromDateToNow < LAST_MEASUREMENT_TIMEOUT
      : false;
    const isBluetooth = lastMeasurementTime
      ? absoluteDiffFromDateToNow < LAST_MEASUREMENT_TIMEOUT
      : false;
    const isInternet = lastMeasurementTime
      ? absoluteDiffFromDateToNow < LAST_MEASUREMENT_TIMEOUT
      : false;

    return {
      ...state,
      isActiveMeasurement,
      isBluetooth,
      isInternet
    };
  }
});

export default auth;
