import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './store';
import App from './app/App';

import { setTokens } from './store/auth/actions';
import './services/api';
import './services/i18next';

import './style/index.scss';
import 'react-loading-skeleton/dist/skeleton.css';

Modal.setAppElement('#modal');
store.dispatch(setTokens());

if (
  (process.env.REACT_APP_SENTRY_DSN !== null
  || process.env.REACT_APP_SENTRY_DSN !== ''
  || !process.env.REACT_APP_SENTRY_DSN)
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById('root')
);
