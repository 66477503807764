import React, {
  memo
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InputText from '../InputText';
import Icon from '../Icon';

import style from './InputSearch.module.scss';
import { noopPromise } from '../../../utils';

const InputSearch = ({
  disabled,
  value,
  onClear,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={style.component}>
      <div className={style.searchIcon}>
        <Icon
          name="Search"
          size="16"
        />
      </div>

      <InputText
        type="text"
        className={style.input}
        disabled={disabled}
        placeholder={t('search')}
        value={value}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />

      {!!value && (
        <button
          type="button"
          className={style.clearButton}
          onClick={onClear}
        >
          <Icon
            name="Clear"
            size="14"
          />
        </button>
      )}
    </div>
  );
};

InputSearch.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onClear: PropTypes.func
};

InputSearch.defaultProps = {
  disabled: false,
  value: '',
  onClear: noopPromise
};

export default memo(InputSearch);
