import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Icon from '../ui/Icon';

import { noopPromise } from '../../utils';

import style from './LogoutButton.module.scss';

const LogoutButton = ({
  onClick
}) => (
  <button
    type="button"
    className={style.component}
    onClick={onClick}
  >
    <Icon
      name="Logout"
      width="20"
      color="#fff"
    />
  </button>
);

LogoutButton.propTypes = {
  onClick: PropTypes.func
};

LogoutButton.defaultProps = {
  onClick: noopPromise
};

export default memo(LogoutButton);
