import React, {
  useCallback,
  memo
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../../utils';

import style from './InputText.module.scss';

const InputText = ({
  type,
  error,
  className,
  onChange,
  ...props
}) => {
  const onChangeProxy = useCallback(({ target }) => {
    onChange(target.value, target.name);
  }, [onChange]);

  const computedClassName = cn(style.component, {
    [style.error]: error
  }, className);

  return (
    <input
      type={type}
      className={computedClassName}
      onChange={onChangeProxy}
      {...props}
    />
  );
};

InputText.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'email',
    'tel',
    'password'
  ]),
  error: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func
};

InputText.defaultProps = {
  type: 'text',
  error: false,
  className: null,
  onChange: noop
};

export default memo(InputText);
