import { createAction } from 'redux-actions';
import {
  SET_DEVICE,
  SET_LANG
} from './constants';

export const setDevice = (device) => ({
  type: SET_DEVICE,
  device
});

export const setLang = createAction(SET_LANG);
