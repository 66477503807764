import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { indexPathSelector } from '../../store/app/selectors';
import { authorizedSelector } from '../../store/auth/selectors';

import Header from './Header';

const mapStateToProps = createStructuredSelector({
  indexTo: indexPathSelector,
  authorized: authorizedSelector
});

export default connect(mapStateToProps)(Header);
