import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LogoutButton from '../LogoutButton';
import LanguageSwitch from '../LanguageSwitch';
import Text from '../ui/Text';

import style from './Header.module.scss';

const Header = ({
  authorized,
  indexTo
}) => (
  <div className={style.component}>
    <div className={style.left}>
      <div className={style.logo}>
        <Link to={indexTo}>
          <Text
            size="L"
            bold
          >
            EviMate
          </Text>
        </Link>
      </div>
    </div>

    <div className={style.right}>
      <div className={style.lang}>
        <LanguageSwitch />
      </div>

      {authorized && (
        <div className={style.logout}>
          <LogoutButton />
        </div>
      )}
    </div>
  </div>
);

Header.propTypes = {
  authorized: PropTypes.bool,
  indexTo: PropTypes.string
};

Header.defaultProps = {
  authorized: false,
  indexTo: '/'
};

export default memo(Header);
