import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { usersListSelector } from '../../../store/users/selectors';

import ConfirmDelete from './ConfirmDelete';

const mapStateToProps = createStructuredSelector({
  users: usersListSelector
});

export default connect(mapStateToProps)(ConfirmDelete);
