import { createSelector } from 'reselect';
import { authorizedSelector } from '../auth/selectors';

const slice = ({ app }) => app;

export const deviceSelector = createSelector(slice, ({ device }) => device);
export const langSelector = createSelector(slice, ({ lang }) => lang);

export const isMobileSelector = createSelector(
  deviceSelector,
  ({ mobile }) => !!mobile
);

export const indexPathSelector = createSelector(
  authorizedSelector,
  (authorized) => (
    !authorized ? '/sign-in/' : '/'
  )
);
