import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';

import style from './Layout.module.scss';

const Layout = ({ children }) => (
  <div className={style.component}>
    <div className={style.main}>
      <div className={style.header}>
        <Header />
      </div>

      <div className={style.content}>
        {children}
      </div>
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node
};

Layout.defaultProps = {
  children: null
};

export default memo(Layout);
