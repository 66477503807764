import { createReducer } from '../utils';
import {
  SET_DEVICE,
  SET_LANG
} from './constants';
import { setCookie } from '../../utils';

const initialState = {
  device: {
    mobile: null,
    os: null
  },
  lang: localStorage.getItem('lang') || 'ru'
};

const app = createReducer(initialState, {
  [SET_DEVICE]: (state, { device }) => ({
    ...state,
    device
  }),
  [SET_LANG]: (state, { payload }) => {
    localStorage.setItem('lang', payload);
    setCookie('django_language', payload);

    return {
      ...state,
      lang: payload
    };
  }
});

export default app;
