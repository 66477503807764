import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  setFilter,
  setSelectedUsers,
  getList,
  setPage
} from '../../../../store/users/actions';

import { filterSelector } from '../../../../store/users/selectors';

import Filter from './Filter';

const mapStateToProps = createStructuredSelector({
  filter: filterSelector
});

const mapDispatchToProps = {
  setFilter: (values) => (dispatch) => {
    dispatch(setSelectedUsers([]));
    dispatch(setFilter(values));
    dispatch(setPage(1));

    return dispatch(getList());
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
