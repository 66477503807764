import React, {
  memo,
  useRef,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '../../../ui/Text';
import Icon from '../../../ui/Icon';
import Dropdown from '../../../ui/Dropdown';
import InputCheckbox from '../../../ui/InputCheckbox';

import { noopPromise } from '../../../../utils';

import style from './Filter.module.scss';

const Filter = ({
  filter,
  setFilter
}) => {
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const computedFilterOptions = useMemo(() => ([
    {
      name: t('normal'),
      value: 'normal'
    },
    {
      name: t('high'),
      value: 'high'
    },
    {
      name: t('lowered'),
      value: 'low'
    }
  ]), [t]);

  return (
    <div className={style.component}>
      <Dropdown
        ref={dropdownRef}
        contentMargin={8}
        trigger={(
          <Icon
            name="Filter"
            color={filter.length === 0 ? '#BAC1C8' : '#10A7C8'}
            size="16"
          />
        )}
      >
        <div className={style.content}>
          <div className={style.header}>
            <Text
              thin
              size="XS"
            >
              {t('filter')}:
            </Text>
          </div>
          <div className={style.variants}>
            {computedFilterOptions.map((f, idx) => {
              const onFilterClick = (e, value) => {
                e.preventDefault();
                e.stopPropagation();
                setFilter(value);
                dropdownRef.current?.closeDropdown();
              };

              return (
                <div // eslint-disable-line
                  key={idx} // eslint-disable-line
                  className={style.variant}
                  onClick={(e) => onFilterClick(e, f.value)} // eslint-disable-line
                >
                  <InputCheckbox
                    checked={filter.includes(f.value)}
                    size="S"
                  />

                  <Text
                    thin
                    size="XS"
                    className={style.variantText}
                  >
                    {f.name}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

Filter.propTypes = {
  filter: PropTypes.array,
  setFilter: PropTypes.func
};

Filter.defaultProps = {
  filter: [],
  setFilter: noopPromise
};

export default memo(Filter);
