import { createAction } from 'redux-actions';
import axios from 'axios';
import {
  SET_TOKEN,
  DELETE_TOKEN
} from './constants';

export const onLoginSuccess = createAction(SET_TOKEN);

export const clearTokens = () => {
  localStorage.removeItem('auth_token');
};

export const setTokens = () => (dispatch) => {
  const lsToken = localStorage.getItem('auth_token') || null;

  if (!lsToken) {
    return;
  }

  dispatch(onLoginSuccess(lsToken));
};

export const loginJWT = ({ username, password }) => (dispatch) => axios.post('auth/sign_in/', {
  username,
  password
}).then(({ data }) => {
  if (data.token) {
    localStorage.setItem('auth_token', data.token);
    dispatch(onLoginSuccess(data.token));
  }
});

export const logout = createAction(DELETE_TOKEN);

// export const refreshToken = () => (dispatch, getState) => dispatch(createActionRequestWithAuth({
//   type: POST_REFRESH_TOKEN,
//   request: {
//     method: 'POST',
//     endpoint: 'token/refresh/',
//     data: {
//       refresh: refreshTokenSelector(getState())
//     }
//   }
// })).then(onTokensUpdate);

// export const setTokens = () => (dispatch) => {
//   const lsToken = localStorage.getItem('auth_token') || null;
//   const lsRefreshToken = localStorage.getItem('auth_refresh_token') || null;

//   if (!lsToken || !lsRefreshToken) {
//     return;
//   }

//   dispatch({
//     type: SET_TOKEN,
//     token: lsToken,
//     refreshToken: lsRefreshToken
//   });

//   dispatch(onLoginSuccess());
// };

// export const addDeferredAction = (action) => ({
//   type: ADD_DEFERRED_ACTION,
//   action
// });

// export const clearDeferredActions = () => ({
//   type: CLEAR_DEFERRED_ACTIONS
// });

// export const onUnauthorized = (deferredAction) => (dispatch, getState) => {
//   dispatch(addDeferredAction(deferredAction));

//   const refreshing = refreshingSelector(getState());

//   if (refreshing) {
//     return Promise.resolve();
//   }

//   return dispatch(refreshToken()).then((action) => {
//     if (!isSuccessAction(action)) {
//       dispatch(clearTokens());
//     } else {
//       const deferredActions = deferredActionsSelector(getState());
//       const promises = deferredActions.map((a) => dispatch(createActionRequestWithAuth(a)));

//       dispatch(clearDeferredActions());

//       Promise.all(promises);
//     }

//     return action;
//   });
// };
