import React, {
  useCallback,
  memo,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../../utils';
import Icon from '../Icon';

import style from './InputCheckbox.module.scss';

const InputCheckbox = ({
  label,
  error,
  className,
  onChange,
  size,
  ...props
}) => {
  const checkIconComputedSize = useMemo(() => (size === 'M' ? '12' : '9'), [size]);
  const onChangeProxy = useCallback(({ target }) => {
    onChange(target.checked, target.name);
  }, [onChange]);

  const computedClassName = cn(style.box, style[`size-${size}`], {
    [style[className]]: className
  }, className);

  return (
    <label
      className={cn(style.component, {
        [style.error]: error,
        [style.noLabel]: !label
      })}
    >
      <input
        type="checkbox"
        className={style.input}
        onChange={onChangeProxy}
        {...props}
      />

      <span
        className={computedClassName}
      >
        <Icon
          name="Check"
          size={checkIconComputedSize}
        />
      </span>

      {!!label && (
        <span className={style.label}>
          {label}
        </span>
      )}
    </label>
  );
};

InputCheckbox.propTypes = {
  label: PropTypes.node,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf([
    'M',
    'S'
  ])
};

InputCheckbox.defaultProps = {
  label: null,
  error: false,
  onChange: noop,
  className: null,
  size: 'M'
};

export default memo(InputCheckbox);
