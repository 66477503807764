import React, {
  useCallback,
  memo
} from 'react';
import PropTypes from 'prop-types';

import useFormState from '../../../hooks/useFormState';

import InputText from '../../ui/InputText';
import Icon from '../../ui/Icon';

import style from './FormAccount.module.scss';
import { noopPromise } from '../../../utils';

const RULES = {
  full_name: {
    required: true
  }
};

const FormAccount = ({
  initialValue,
  onReset,
  onSubmit
}) => {
  const {
    onFormSubmit,
    onFieldChange,
    formState,
    changed,
    processing,
    errors,
    setForm
  } = useFormState(initialValue, RULES, onSubmit, onReset);

  const editModeCloseWithClear = useCallback(() => {
    onReset();
    setForm(initialValue);
  }, [initialValue, setForm, onReset]);

  return (
    <div className={style.component}>
      <InputText
        type="text"
        name="full_name"
        className={style.input}
        placeholder="Имя пользователя"
        value={formState.full_name}
        error={!!errors.full_name}
        disabled={processing}
        onChange={onFieldChange}
      />

      <div className={style.actionButtons}>
        {changed && (
          <button
            type="button"
            className={style.blue}
            onClick={onFormSubmit}
          >
            <Icon
              name="Done"
              width="17"
              height="13"
            />
          </button>
        )}

        <button
          type="button"
          className={style.clearButton}
          onClick={editModeCloseWithClear}
        >
          <Icon
            name="Clear"
            size="14"
          />
        </button>
      </div>
    </div>
  );
};

FormAccount.propTypes = {
  onReset: PropTypes.func,
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func
};

FormAccount.defaultProps = {
  onReset: noopPromise,
  initialValue: {
    full_name: ''
  },
  onSubmit: noopPromise
};

export default memo(FormAccount);
